export const boxStyle = {
    flexGrow: 1,
    padding: '20px'
}

export const textStyle = {
    color: 'white',
}

export const preloaderStyle = {
    color: '#DC7F0A'
}

export const iconStyle = {
    color: '#f79009'
}

export const filterIconStyle = {
    color: '#f79009',
    cursor: 'pointer'
}

export const gridStyle = {
    marginTop: '30px'
}

export const autoCompleteStyle = {
    width: 300,
    color: 'white',
    padding:'3px',
    borderRadius: '10px',
    background: 'black',
    // border: '1px solid grey',
    '& .MuiOutlinedInput-root': {
        color: '#f79009',
        backgroundColor: 'black',
    },
    '& .MuiSvgIcon-root': {
        color: '#f79009',
    },
    '& .MuiInputLabel-root': {
        color: '#f79009',
    },
    '& .MuiAutocomplete-listbox': {
        backgroundColor: 'black',
        color: 'white',
    },
    '& .MuiAutocomplete-option': {
        '&[aria-selected="true"]': {
            backgroundColor: '#333',
        },
        '&:hover': {
            backgroundColor: '#444',
        },
    },
}

export const graphPaperStyle = {
    background: 'black',
    border:'1px solid slategrey',
    borderRadius:'10px',
    padding:'5px',
}
