export const getFileName = (path:string) => {
    switch(path)
    {
        case 'viewimages' : return 'VIEW IMAGES';
        case 'exploredataset' : return 'EXPLORE DATASET';
        case 'analytics' : return 'ANALYTICS';
        case 'settings' : return 'ACTIVATE KEY';
        case 'organizationdetails' : return 'ORGANIZATION DETAILS';
        case 'dataaudit' : return 'DATA AUDIT';
        case 'workflow' : return 'WORKFLOW';
    }
}