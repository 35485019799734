import React from "react";
import { Typography } from "@mui/material";
import PinDropIcon from '@mui/icons-material/PinDrop';
import { PreLoader } from "./preloader";
import '../../styles/PointInfo.css';
import { PointInfoProps } from "../../common/types";
import { categoryNameTextStyle, categoryValueTextStyle, pinDropIconStyle } from "../../constants/PointInfoConst";

const PointInfo: React.FC<PointInfoProps> = ({ point, image }) => {
  let categoryExcluded:string[] = ['id','coordinates','dataset_id'];
  return (
    <>
      {!point ? (
        <>
          <PreLoader preloaderCheck={point}/>
        </>
      ) : (
        <div className="pointInfoDetailDiv">
          <img src={image.url[0]} alt="The point depicted visually" width="200px"></img>
            {Object.entries(point).map(([categoryType, categoryValue], index) => (
              <div key={index}>
                {categoryType !== null && categoryValue!==null && !categoryExcluded.includes(categoryType) && (
                  <div>
                    <Typography variant="subtitle1" gutterBottom sx={categoryNameTextStyle}>
                      {categoryType.split("_").join(" ").toUpperCase()}
                    </Typography>
                    {Array.isArray(categoryValue) ? (
                      <>
                        {categoryValue.map((subData, subIndex) => (
                          <div key={subIndex} className="categoryDiv">
                            <PinDropIcon sx={pinDropIconStyle}/>
                            <Typography variant="body2" sx={categoryValueTextStyle} gutterBottom>
                              {subData}
                            </Typography>
                          </div>
                        ))}
                      </>
                    ) : (
                      <Typography variant="body2" sx={categoryValueTextStyle}gutterBottom>
                        {categoryValue}
                      </Typography>
                    )}
                  </div>
                )}
              </div>
            ))}
        </div>
      )}
    </>
  );
};

export default PointInfo;
