export const textColor = {
    color : 'white'
}

export const buttonColor = {
    color : '#f79009'
}

export const iconColor = { 
    color: '#f79009' , 
    alignSelf:'center' 
}

export const addIconStyle = {
    color: '#f79009' , 
    alignSelf:'center',
    fontSize : '30px'
}

export const workflowNodeStyle = {
    border: '1px solid #f79009',
    borderRadius: '5px',
    padding: '5px', 
    width: '100px',
    height: 'max-content',
    overflow: 'hidden',
    fontSize: '12px',
    wordBreak: 'break-word',
    whiteSpace: 'normal',
    textAlign: 'center',
  }