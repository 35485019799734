import './App.css';
import { Routes, Route } from "react-router-dom";
import Footer from './components/landing/subcomponents/Footer';
import NavBar from './components/landing/NavBar';
import LoginPage from './components/landing/LoginPage';
import ForgotPassword from './components/landing/ForgotPassword';
import Dashboard from './components/landing/Dashboard';
import OAuthCallback from './components/landing/OAuthCallback';
import RegisterPage from './components/landing/RegisterPage';
import ResetPassword from './components/landing/ResetPassword';
import DatasetDetails from './components/landing/DatasetDetails';
import ViewImages from './components/landing/ViewImages';
import ExploreDataset from './components/landing/ExploreDataset';
import Analytics from './components/landing/Analytics';
import { ContainerStyles } from './components/styles/NavBarStyles.styles.tw';
import ProfileSettings from './components/landing/ProfileSettings';
import { ErrorPage } from './components/landing/subcomponents/404Page';
import IncidentTable from './components/landing/DataAudit';
import OrganizationSettings from './components/landing/OrganizationSettings';
import { Workflow } from './components/landing/subcomponents/workflow';

function App() {
  return (
    <ContainerStyles>
      <NavBar />
      <Routes>
        <Route path="/" element={<LoginPage />} />
        <Route path="/login" element={<LoginPage />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/register" element={<RegisterPage />} />
        <Route path="/dashboard" element={<Dashboard />} />
        <Route path="/oauthcallback" element={<OAuthCallback />} />
        <Route path="/settings" element={<ProfileSettings />} />
        <Route path="/organizationdetails" element={<OrganizationSettings />} />
        <Route path="/viewimages" element={<ViewImages />} />
        <Route path="/exploredataset" element={<ExploreDataset />} />
        <Route path="/analytics" element={<Analytics />} />
        <Route path="/datasetdetails" element={<DatasetDetails datasetName={''} createdDate={''} createdBy={''} updatedDate={''} imageUrl={''} datasetId={''} updated_at={''} completenessScore={0} dataQualityScore={0} consistencyScore={0} scoreState={''} task={''} modelState={''}  auditState={''} graphMethod={''} />} />
        <Route path="/dataaudit" element={<IncidentTable />} />
        <Route path="/workflow" element={<Workflow />} />
        <Route path="*" element={<ErrorPage />} />
      </Routes>
      <Footer />
    </ContainerStyles>
  );
}

export default App;
