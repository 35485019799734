export const paperStyle = {
  p: '40px',
  maxWidth: '380px',
  height: 'auto',
  width: { xs: '90%', sm: '70%', md: '60%', lg: '400px' },
  margin: '9% auto',
  background: 'rgba(255, 165, 79, 0.75)',
  borderRadius: '15px',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
  mx: 'auto',
  mt: '5%'

};

export const inputStyle = {
  backgroundColor: '#fff',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: '#eee'
  }
};

export const buttonStyle = {
  mt: '30px',
  backgroundColor: '#333',
  color: '#fff',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: '#555'
  }
};

export const outlinedButtonStyle = {
  mt: '20px',
  borderColor: '#fff',
  color: '#fff',
  transition: '0.3s',
  '&:hover': {
    borderColor: '#aaa',
    color: '#aaa'
  }
};

export const createAccountButtonStyle = {
  mt: '20px',
  borderColor: '#FFFFFF',
  color: '#FFFFFF',
  transition: '0.3s',
  boxShadow: '0 4px 6px rgba(50, 50, 93, 0.11), 0 1px 3px rgba(0, 0, 0, 0.08)',
  '&:hover': {
    backgroundColor: 'rgba(176, 155, 70, 0.6)',
    transform: 'translateY(-2px)'
  }
};

export const mainContainerStyle = {
  display: 'flex',
  flexDirection: 'column',
  minHeight: '100vh',
  justifyContent: 'space-between',
  background: '#000',
};

export const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

export const gridStyle = { 
  height: '100%'
}

export const errorTextStyle = { 
  marginBottom: '10px'
}