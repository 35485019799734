export const getFileFormat = (url:string) => {
    if(url.includes('.jpg'))
    {
      return 'jpg'
    }
    else if(url.includes('.jpeg'))
    {
      return 'jpeg'
    }
    else if(url.includes('.png'))
    {
      return 'png'
    }
    else if(url.includes('.bmp'))
    {
      return 'bmp'
    }
    else
    {
      return null;
    }
  }