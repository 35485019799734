import React, { useState, Suspense, useEffect } from 'react';
import { Button, TextField, Grid, Paper,IconButton, Typography, Link, Divider, Box, Fade } from '@mui/material';
import GoogleIcon from '@mui/icons-material/Google';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link as RouterLink } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import '../styles/LoginPage.css'
import awsExports from '../services/aws-exports';
import { CognitoHostedUIIdentityProvider } from "@aws-amplify/auth";
import { emailRegex ,paperStyle , inputStyle , buttonStyle , outlinedButtonStyle , createAccountButtonStyle , mainContainerStyle, gridStyle, errorTextStyle } from '../constants/LoginPageConst';
import VerifyAPIKey from './SetKey';


Auth.configure(awsExports);

const LoginPage: React.FC = () => {
  const [loading, setLoading] = useState(false);
  const [credentials, setCredentials] = useState({ username: '', password: '' });
  const [error, setError] = useState<string | null>(null);
  const [showPassword, setShowPassword] = useState(false);
  const navigate = useNavigate();
  
  useEffect(() => {
    const checkUserSession = async () => {
      try {
        const session = await Auth.currentSession(); 
        console.info('Current user session:', session);
        navigate('/dashboard');
      } catch (error) {
        console.error('No valid session found:', error);
      }
    };
    checkUserSession();
  }, [navigate]);  

  const handleLogin = async () => {
    setLoading(true);
    setError('');
  if (!credentials.username) {
    setError('Username is a required field.');
    setLoading(false);
    return;
  }

  if (!credentials.password) {
    setError('Password is a required field.');
    setLoading(false);
    return;
  }

  if (!emailRegex.test(credentials.username)) {
    setError('Invalid email address.');
    setLoading(false);
    return;
  }
    try {
      await Auth.signIn(credentials.username, credentials.password);
      const key = await VerifyAPIKey();
      key && navigate('/dashboard');
      window.location.reload();
    } catch (error) {
      console.error('Login error:', error);
      setError('Login failed. Please try again.');
    } finally {
      setLoading(false);
    }
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setCredentials(prevState => ({ ...prevState, [name]: value }));
  };

  const togglePasswordVisibility = () => {
   setShowPassword(!showPassword);
  };

  const handleGoogleSignIn = async () => {
    try {
      await Auth.federatedSignIn({ provider: CognitoHostedUIIdentityProvider.Google });
    } catch (error) {
      console.error('Error during Google Sign-in:', error);
      setError('Failed to sign in with Google. Please try again.');
    }
  };

  return (
    <div>
      <Suspense fallback={<div>Loading...</div>}>
      <Box sx={mainContainerStyle}>
        <Grid container spacing={2} alignItems="center" justifyContent="center" style={gridStyle}>
          <Grid item xs={12} sm={12} md={6} lg={6} >
            <div className="contentDiv">
              <div className='imageDiv' style={{display:`${localStorage.getItem('resolution')==='desktop' ? 'block' : 'none'}`}}><img className="image" src="./images/Logo_.png" alt="Logo" width="400px" /></div>
              <Typography variant="h6" sx={{ color: '#fff', mt: 2,   }}>
              Fueling Data-Centric Computer Vision.
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Fade in={true} timeout={1000}>
              <Paper elevation={10} sx={paperStyle}>
                <Typography variant="h4" align="center" gutterBottom sx={{ color: '#fff', fontWeight: 'bold' }}>
                  Get Started!
                </Typography>
                <Divider sx={{ mb: '20px', backgroundColor: '#fff' }} />
                <TextField name="username" fullWidth margin="normal" label="Username" variant="filled" value={credentials.username} onChange={handleChange} sx={inputStyle} />
                <Grid container direction="column" spacing={2}>
                <Grid item>
                    <TextField 
                        name="password"
                        fullWidth 
                        margin="normal" 
                        label="Password" 
                        type={showPassword ? 'text' : 'password'}
                        variant="filled" 
                        value={credentials.password} 
                        onChange={handleChange} 
                        sx={inputStyle}
                        InputProps={{
                          endAdornment: (
                            <IconButton
                              onClick={togglePasswordVisibility}
                              edge="end"
                            >
                              {showPassword ? <VisibilityOff /> : <Visibility />}
                            </IconButton>
                          ),
                        }}
                    />
                </Grid>
                <Grid item container justifyContent="flex-end">
                <Link href="/forgot-password" underline="none" sx={{ color: '#fff', transition: '0.3s', '&:hover': { color: '#aaa' } }}>
                    Forgot Password?
                </Link>
                </Grid>
                </Grid>

                {error && <Typography color="error" style={errorTextStyle}>{error}</Typography>}
                <Button fullWidth variant="contained" color="primary" sx={buttonStyle} onClick={handleLogin}>Login</Button>
                <Button fullWidth variant="outlined" startIcon={<GoogleIcon />} sx={outlinedButtonStyle}  onClick={handleGoogleSignIn} >Sign in with Google</Button>
                <Grid container alignItems="center" justifyContent="center" sx={{ mt: '20px' }}>
                    <Grid item xs={5}>
                    <Divider sx={{ backgroundColor: '#fff' }} />
                    </Grid>
                <Grid item xs={2}>
                    <Typography align="center" sx={{ color: '#fff' }}>
                     OR
                    </Typography>
                    </Grid>
                <Grid item xs={5}>
                <Divider sx={{ backgroundColor: '#fff' }} />
                </Grid>
                </Grid>
                <Button fullWidth variant="outlined" startIcon={<AccountCircleIcon />} sx={createAccountButtonStyle} component={RouterLink} to="/register">
                  Create a New Account
                </Button>
              </Paper>
            </Fade>
          </Grid>
        </Grid>
      </Box>
    </Suspense>
    </div>
  );
};

export default LoginPage;
