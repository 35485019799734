import { Player } from '@lottiefiles/react-lottie-player';
import { Typography } from '@mui/material';
import { NoDataProps } from '../../common/types';
import '../../styles/NoDataPage.css'
import { playerStyle, textStyle } from '../../constants/NoDataConst';

export const NoDataPage:React.FC<NoDataProps> = ({displayText}) => {
    return (
        <div className="playerDivStyle">
            <div>
            <Player
            autoplay
            loop
            src="https://lottie.host/9aba58ad-261a-46f6-803d-cac0a3ba5eb8/RCd9WFOucv.json"
            style={playerStyle}
        >
        </Player>
        </div>
            <div>
            <Typography variant="h5" sx={textStyle}>
              {displayText}
            </Typography>
            </div>
        </div>
    )
}
