export const backdropStyle = {
    zIndex:'100',
    background:'#000'
}

export const closeIconStyle={
    fontSize:'30px',
    background:'#f79009',
    color:'black',
    borderRadius:'10px',
    margin:'20px',
    cursor:'pointer'
}

export const iconStyle = { 
    color:'#f79009'
}

export const textStyle = { 
    color: '#f79009'
}

export const toggleColor = {
    color: "#f79009",
    '&.Mui-selected': {
        backgroundColor: "#5545",
        color: "#f79009",
    },
}

export const toggleBorderColor = {
    border : "1px #f79009"
}