import React from 'react';
import { CircularProgress } from '@mui/material';
import { PreloaderProps } from '../../common/types';
import '../../styles/Preloader.css'
import { preloaderColorStyle } from '../../constants/PreloaderConst';

export const PreLoader: React.FC<PreloaderProps> = ({ preloaderCheck }) => {
    return (
        <>
            {preloaderCheck && (
                <div className='preloaderDiv'>
                    <CircularProgress sx={preloaderColorStyle} size={'80px'} />
                </div>
            )}
        </>
    );
};