export const boxStyle = {
    height: '100vh',
    animation: 'gradientChange 15s infinite',
    backgroundSize: '150% 150%',
    backgroundColor: '#000'
}

export const gridStyle = {
    height: '100%'
}

export const paperStyle = {
    p: '40px',
    width: '400px',
    background: 'rgba(255, 165, 79, 0.75)',
    borderRadius: '15px',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    backdropFilter: 'blur(8.5px)',
    border: '1px solid rgba(255, 255, 255, 0.18)'
}

export const textStyle = {
    color: '#fff',
    fontWeight: 'medium'
}

export const dividerStyle = {
    mb: '30px',
    backgroundColor: '#fff'
}

export const textFieldStyle = {
    backgroundColor: '#fff',
    transition: '0.3s',
    '&:hover': { backgroundColor: '#eee' }
}

export const mailOutlineIconStyle = {
    mt: '20px'
}

export const button1Style = {
    mt: '70px',
    backgroundColor: '#333',
    color: '#fff',
    transition: '0.3s',
    '&:hover': { backgroundColor: '#555' }
}

export const dialogContentStyle = {
    textAlign: 'center'
}