let token:string | null = null;
export const accessToken = () => {
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('accessToken')) {
        token = localStorage.getItem(key);
      }
    });
    return token;
  };

export const accessTokenParams = {
    headers: {
      'authorization': `Bearer ${accessToken()}`
    }
  };