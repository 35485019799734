import { getMembershipKey } from "../services/Endpoints";
import { accessToken } from "../common/GetAccessToken";

const VerifyAPIKey = async () => {
  const token = accessToken();
  if (!token) {
    console.error('Access token not found in localStorage');
    return false;
  }

  try {
    const accessTokenParams = {
      headers: {
        'authorization': `Bearer ${token}`
      }
    };
    const response = await getMembershipKey(accessTokenParams)
    if (!response.ok) {
      throw new Error('Network response was not ok');
    }
    const data = await response.json();
    localStorage.setItem('api-key', `${data.key}`);
    return true; 
  } catch (error) {
    console.error('There was a problem with the fetch operation:', error);
    return false;
  }
};


export default VerifyAPIKey;