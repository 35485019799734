export const appBarStyle = {
  backgroundColor: 'rgba(44, 44, 44, 0.95)',
  boxShadow: 'none'
};

export const containerStyle = {
  mt: 4,
  mb: 4,
  color: '#fff',
  height: '100%'
};

export const paperStyle = {
  ...containerStyle,
  p: 2,
  bgcolor: 'rgba(255, 165, 79, 0.75)',
  borderRadius: '15px',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)'
};

export const gridItemStyle = {
  height: 140,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  bgcolor: '#e0e0e0',
  borderRadius: '10px',
  boxShadow: 'inset 0 0 10px rgba(0, 0, 0, 0.3)'
};

export const paperFlexStyle = {
  ...gridItemStyle,
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
};

export const addButtonStyle = {
  fontSize: 60,
  color: 'rgba(255, 255, 255, 0.7)'
};

export const paginationStyle = {
  '.MuiPaginationItem-root': {
    color: 'white'
  }
};

export const backgroundStyle = {
  backgroundColor: '#000',
  minHeight: '90vh'
};

export const combinedPaperStyle = {
  ...gridItemStyle,
  ...paperStyle,
  ...paperFlexStyle
};

export const boxStyle = {
  my: 4,
  mx: 'auto',
  textAlign: 'center',
  p: 3,
  width: '100%',
  backgroundColor: '#121212',
  borderRadius: '0',
  color: '#ff9800',
  boxShadow: 'inset 0 0 10px #ff9800',
}

export const header1Style = {
  fontWeight: 'bold',
  color: '#ffffff',
  textShadow: '2px 2px 4px rgba(0,0,0,0.5)',
}

export const header2Style = {
  fontWeight: 'medium',
  color: '#ff9800',
}

export const header3Style = {
  fontWeight: 'bold'
}

export const datasetViewStyle = {
  ...combinedPaperStyle,
  backgroundImage: `url(https://i.seadn.io/gae/OGpebYaykwlc8Tbk-oGxtxuv8HysLYKqw-FurtYql2UBd_q_-ENAwDY82PkbNB68aTkCINn6tOhpA8pF5SAewC2auZ_44Q77PcOo870?auto=format&dpr=1&w=3840)`,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  cursor: 'pointer'
}

export const datasetTextStyle = {
  mt: 2
}

export const paginationDivStyle ={ 
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  p: 4,
  mt: 4
}