export const categoryNameTextStyle = { 
    color: '#f79009',
    fontWeight:'700'
}

export const pinDropIconStyle = {
    color:'whitesmoke'
}

export const categoryValueTextStyle = {
    color:'white'
}