import React, { useEffect, useState } from 'react';
import { Typography } from '@mui/material';
import '../styles/OrganizationProfile.css'
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AlertComponent from './subcomponents/AlertComponent';
import { getDatasets } from '../services/Endpoints';
import { PreLoader } from './subcomponents/preloader';
import { NoDataPage } from './subcomponents/NoData';
import { headerStyle, datasetCount , contentStyle , subHeader, premiumPlanStyle } from '../styles/OrganizationSettingsConst';
import Swal from 'sweetalert2';


const OrganizationSettings: React.FC = () => {
  const [preloader , setPreloader] = useState<boolean>(true);
  const [keyMessage , setKeyMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorType , setErrorType] = useState<string>("");
  const [data , setData] = useState<any>({});
  const navigate = useNavigate();
  const location = useLocation();
  const { organizationName } = location?.state;
    useEffect(() => {
        if (!location.state) {
            navigate('/dashboard');
          }
        const fetchData = async () => {
            try{
                const response = await getDatasets(1);
                const data = await response.json();
                setData(data);
                setPreloader(false);
            }
            catch(err:any)
            {
                setKeyMessage(err.message);
                setErrorType('error');
                setOpenSnackbar(true);
                setPreloader(false);
            }
        }
        fetchData();
    },[location, navigate]);

    const getPremiumDetails = () => {
        Swal.fire({
            title: "Premium Features",
            background:'black',
            color:'white',
            html: `
              <div class="container" style="display:flex;flex-direction:column;gap:10px;text-align:left">
                <li><span style="color:#f79009">Dataset Limit</span> : 5</li>
                <li><span style="color:#f79009">Instances within the dataset Limit</span> : Unlimited</li>
              </div>
              <div class="premiumSubscribeLinkDiv">
                <h6> To enjoy premium features in the platform , reach us out to <a href="mailto:support@cortalinsight.com?subject=Premium Subscription : ${organizationName}" class="linkStyle" target="_blank">support@cortalinsight.com</a> </h6>
              </div>
            `,
            showConfirmButton:false,
            // confirmButtonText:'Upgrade to Premium',
            // confirmButtonColor:'#f79009',
            showCancelButton: false,
            showCloseButton: true,
          })
    }

  return (
      <>
        {preloader ? (
            (<><PreLoader preloaderCheck={preloader}/></>)
        ) : (
            <div className='organizationProfileDiv'>
            <Typography variant='h4' sx={headerStyle}>Welcome {organizationName!==undefined ? organizationName.split(" ").map((cate: string) => cate.slice(0, 1).toUpperCase() + cate.slice(1)).join("") : 'User'}</Typography>
            {Object.keys(data).length > 0 ? (
                <div className='' >
                    <div className='organizationDetails'>
                        <div className="planDetailsDiv">
                            <div>
                                    {localStorage.getItem('Membership') === 'Free' && 
                                        <div className='premiumPlanDetailsDiv'>
                                            <Typography onClick={getPremiumDetails} variant='body1' sx={premiumPlanStyle}>Explore Premium Features</Typography>
                                        </div>
                                    }
                                <Typography variant='h6' sx={subHeader}>{localStorage.getItem('Membership')} Plan</Typography>
                                <div>
                                    {/* <Typography variant='h6' sx={datasetCount}>Datasets Available : {data.dataSets.length} / {localStorage.getItem('Membership') !== 'free' ? 5 : 2}</Typography> */}
                                    <Typography variant='h6' sx={datasetCount}>Datasets Available : {data.dataSets.length}</Typography>
                                </div>
                            </div>
                        </div>
                        
                        <Typography variant='h4' sx={headerStyle}>Dataset Info:</Typography>
                        <div className='datasetDetailsDiv'>
                            {data.dataSets.map((data: any, index: number) => {
                                return (
                                    <div className='datasetDiv'>
                                        <Typography variant='h6' sx={subHeader}>{data.dataset_name.toUpperCase()}</Typography>
                                        <div className='dataCapacityDetailsDiv'>
                                            <Typography variant='h6' sx={contentStyle}>Instances in workspace: {data.num_of_images!==null ? data.num_of_images : 0}</Typography>
                                           {localStorage.getItem('Membership') === 'Free' && <Typography variant='h6' sx={contentStyle}>Instances available: {50000 - data.num_of_images}</Typography>} 
                                        </div>
                                        <Link
                                            to="/datasetdetails"
                                            state={{
                                                datasetName: data.dataset_name,
                                                datasetId: data.id,
                                                createdDate: data.created_at,
                                                createdBy: data.created_by,
                                                updatedDate: data.updated_at,
                                                imageUrl: data.image_url,
                                                dataQualityScore: data.data_quality_score,
                                                completenessScore: data.completeness,
                                                consistencyScore: data.consistency,
                                                scoreState: data.score_state,
                                                modelState: data.model_state,
                                                auditState: data.audit_state,
                                                graphMethod: data.graph_method,
                                                task: data.task !== null && data.task
                                            }}
                                            onClick={(e) => {
                                                localStorage.setItem('organization_id', data.organization_id);
                                            }}
                                        >
                                            <Typography variant="subtitle1" align="center" sx={subHeader}>
                                                View Dataset Details
                                            </Typography>
                                        </Link>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                </div>
            ) : (
                <NoDataPage displayText={'Lost in space, please check back after some time'} />
            )}
            <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)} />
        </div>
        )}
      </>
  );
};

export default OrganizationSettings;
