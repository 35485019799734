import Fuse, { IFuseOptions } from 'fuse.js'; 

export function fuzzySearch(data: string[], options: IFuseOptions<string> | undefined, search: string) {
    let categoryFilter: string[] = [];
    const fuse = new Fuse(data, options);
    const result = fuse.search(search);
    result.forEach(({ item }) => {
      categoryFilter.push(item);
    });
    return categoryFilter;
  }