import React, { useState } from 'react';
import { Button, TextField, Grid, Paper, Typography, Box, Fade, MobileStepper, IconButton, InputAdornment } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import { Link as RouterLink, useNavigate} from 'react-router-dom';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import AlertComponent from './subcomponents/AlertComponent';
import { UserData } from '../common/types';
import '../styles/RegisterPage.css'
import { inputStyle, otpInputStyle , verifyButtonStyle ,paperStyle, boxStyle, text1Style, mobileStepperStyle, navigateButtonStyle, regenerateOTPBoxStyle, errorMessageTextStyle, homepageTextStyle, routerStyle, validationTextStyle  } from '../constants/RegisterPageConst';
import { confirmUserSignUpPOST, getresendCodeSignUp, userSignUpPOST } from '../services/Endpoints';
import { EncodeData } from '../services/encodeData';


const RegisterPage: React.FC = () => {
    const [userData, setUserData] = useState<UserData>({
        firstName: '',
        middleName: '',
        lastName: '',
        email: '',
        password: '',
        companyName: '',
        designation: '',
        phone: '',
        location: '',
        zipcode: '',
        dob: null,
        profilePicture: null,
        showPassword: false
    });
    const nameWithSpacesRegex = /^[a-zA-Z]+(?: [a-zA-Z]+)*$/;
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    // const phoneRegex = /^\+\d{1,2}\s?\(?\d{3}\)?[-\s]?\d{3}[-\s]?\d{4}$/; 
    const usZipCodeRegex = /^\d{5}$/;
    const [step, setStep] = useState<number>(0);
    const [confirmPassword, setConfirmPassword] = useState<string>(''); 
    const [showPasswordRules, setShowPasswordRules] = useState<boolean>(false);
    const [errorMessage, setErrorMessage] = useState<string | null>(null);
    const [keyMessage, setKeyMessage] = useState<string>('');
    const [openSnackbar, setOpenSnackbar] = useState(false);
    const [errorType, setErrorType] = useState<string>('');
    const [otp, setOtp] = useState<string>(''); 
    const [otpVisible, setOtpVisible] = useState(false);
    const [otpGenerate , setOTPGenerate] = useState<boolean>(false)
    const navigate = useNavigate();

    const passwordCriteria = {
        isLengthValid: userData.password.length >= 12,
        isLengthBetter: userData.password.length >= 14,
        hasUpperCase: /[A-Z]/.test(userData.password),
        hasLowerCase: /[a-z]/.test(userData.password),
        hasNumber: /[0-9]/.test(userData.password),
        hasSpecialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(userData.password),
    };

    const isCombinationValid = passwordCriteria.hasUpperCase && passwordCriteria.hasLowerCase && passwordCriteria.hasNumber && passwordCriteria.hasSpecialChar;

    const validateStepOne = (): string | null => {
        if (!userData.firstName) {
          return "The First Name field cannot be empty.";
        } else if (!userData.firstName.match(nameWithSpacesRegex)) {
          return "The First Name field can contain only letters.";
        }
        if (!userData.middleName) {
          } else if (!userData.middleName.match(nameWithSpacesRegex)) {
            return "The Middle Name field can contain only letters.";
          }
        if (!userData.lastName) {
          return "The Last Name field cannot be empty.";
        } else if (!userData.lastName.match(nameWithSpacesRegex)) {
          return "The Last Name field can contain only letters.";
        }
        if (!userData.email) {
          return "The Email field cannot be empty.";
        } else if (!userData.email.match(emailRegex)) {
          return "Invalid email format.";
        }
        if (!userData.password) {
          return "The Password field cannot be empty.";
        } else if (userData.password.length < 12 || !userData.password.match(/^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[^\da-zA-Z]).{12,}$/)) {
          return "Please make sure your password matches the above criteria.";
        }
        if (userData.password !== confirmPassword) {
          return "Passwords do not match.";
        }
        return null; 
    };

    const validateStepTwo = (): string | null => {
        if (!userData.companyName) {
          return "The Company Name field cannot be empty.";
        }
        if (!userData.designation) {
          return "The Designation field cannot be empty.";
        } else if (!userData.designation.match(nameWithSpacesRegex)) {
          return "The Designation field can contain only letters.";
        }
        if (!userData.location) {
          return "The Location field cannot be empty.";
        } else if (!userData.location.match(nameWithSpacesRegex)) {
         return "Location should revolve around valid demographics "
        }
        if (!userData.zipcode) {
          return "The Zipcode field cannot be empty.";
        } else if (!userData.zipcode.match(usZipCodeRegex)) {
          return "Invalid Zipcode";
        }
        return null;
    };

    const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const { name, value } = e.target;
        setUserData(prevState => ({ ...prevState, [name]: value }));
        if (errorMessage) setErrorMessage(null);
    };

    const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setConfirmPassword(e.target.value);
        if (errorMessage) setErrorMessage(null);
    };

    const handleNextStep = async () => {
        let validationMessage;
        if (step === 0) {
            validationMessage = validateStepOne();
        } else if (step === 1) {
            validationMessage = validateStepTwo();
        }
    
        if (validationMessage) {
            setErrorMessage(validationMessage);
            return;
        }
    
        if (step === 1) {
            try {
                const payload = JSON.stringify({
                    firstName : userData.firstName,
                    lastName : userData.lastName,
                    email : userData.email,
                    password : EncodeData(userData.password),
                    companyName : userData.companyName,
                    designation : userData.designation
                });
                const response = await userSignUpPOST(payload,'POST')
                const data = await response.json();
                if(response.status === 200)
                {
                    setStep(step + 1); 
                }
                else
                {
                    setKeyMessage(`An error occurred , ${data.errors[0].message}`);
                    setErrorType('error');
                    setOpenSnackbar(true);
                }
            } catch (error) {
                if (error instanceof Error) {
                    setErrorMessage(error.message);
                } else {
                    setErrorMessage("An unexpected error occurred during sign up.");
                }
            }
        } else {
            setStep(step + 1);
        }
    };

    const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        setOtp(e.target.value);
        if (errorMessage) setErrorMessage(null);
    };

    const toggleOtpVisibility = () => {
        setOtpVisible(!otpVisible);
    };
    const handleVerifyOtp = async () => {
        if (!otp) {
            setErrorMessage("One-Time Password field cannot be empty.");
            return;
        }if (!otp.match(/^\d{6}$/)) {
            setErrorMessage("One-Time Password must be a valid six digit number.");
            return;
        }
    
        try {
            const payload = JSON.stringify({
                confirmationCode : otp,
                username : userData.email
            });
            const response = await confirmUserSignUpPOST(payload,'POST');
            const data = await response.json();
            if(response.status === 200)
            {
                navigate('/login');
            }
            else
            {
                setKeyMessage(`Error: ${data.errors[0].message}`);
                setErrorType('error');
                setOpenSnackbar(true);
            }

        } catch (error) {
            if (error instanceof Error) {
                setErrorMessage(error.message);
            } else {
                setErrorMessage("An unexpected error occurred during confirmation.");
            }
        }
    };

    const regenerateOTP = async () => {
        const response = await getresendCodeSignUp(userData.email);
        const data = await response.json();
        if(response.status === 200){
            setOTPGenerate(true) 
        }
        else
        {
            setKeyMessage(`Error: ${data.errors[0].message}`);
            setErrorType('error');
            setOpenSnackbar(true);
        }
    }
    
    const handleBackStep = () => {
        if (step === 0) {
            navigate('/login'); 
        } else if (step > 0) {
            setStep(step - 1);
            if (errorMessage) setErrorMessage(null);
        }
    };

    const handleClickShowPassword = () => {
        setUserData({ ...userData, showPassword: !userData.showPassword });
    };
       
    return (
        <Box sx={boxStyle}>
            <Grid container alignItems="center" justifyContent="center">
                <Grid item xs={12} md={6} lg={4}>
                    <Fade in={true} timeout={1000}>
                      <div className='div1Style'>
                        <div className='div2Style'>
                        <Paper elevation={24} sx={paperStyle}>
                            {step !== 2 && (<Typography variant="h4" align="center" gutterBottom sx={text1Style}>
                            Create an Account
                            </Typography>)}
                            {step < 2 && <MobileStepper sx={mobileStepperStyle} variant="dots" steps={3} position="bottom" activeStep={step} backButton={<Button size="small" style={navigateButtonStyle} onClick={handleBackStep}>BACK</Button>} nextButton={<Button size="small" style={navigateButtonStyle} onClick={handleNextStep}>{step === 1 ? 'CONFIRM' : 'Next'}</Button>} />}

                            {step === 0 && <>
                                <TextField name="firstName" fullWidth margin="normal" label="First Name" variant="filled" value={userData.firstName} onChange={handleChange} sx={inputStyle} required />
                                <TextField name="middleName" fullWidth margin="normal" label="Middle Name" variant="filled" value={userData.middleName} onChange={handleChange} sx={inputStyle} />
                                <TextField name="lastName" fullWidth margin="normal" label="Last Name" variant="filled" value={userData.lastName} onChange={handleChange} sx={inputStyle} required />
                                <TextField name="email" fullWidth margin="normal" label="Email" type="email" variant="filled" value={userData.email} onChange={handleChange} sx={inputStyle} required />
                                <TextField name="password" fullWidth margin="normal" label="Password" type={userData.showPassword ? 'text' : 'password'} variant="filled" value={userData.password} onChange={handleChange}  onFocus={() => setShowPasswordRules(true)} sx={inputStyle} required
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClickShowPassword}>
                                                {userData.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        ),
                                    }}
                                />
                                <div className='passwordValidationDiv'>
                                    <div className='validationDiv'>
                                        {passwordCriteria.isLengthValid ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error" />}
                                        <Typography variant="body1" sx={validationTextStyle}>Minimum 12 characters</Typography>
                                    </div>
                                    <div className='validationDiv'>
                                        {isCombinationValid ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error" />}
                                        <Typography variant="body1" sx={validationTextStyle}>Combination of alphabets,numbers,and symbols</Typography>
                                    </div>
                                </div>
                                <TextField name="confirmPassword" fullWidth margin="normal" label="Confirm Password" type={userData.showPassword ? 'text' : 'password'} variant="filled"value={confirmPassword} onChange={handleConfirmPasswordChange} sx={inputStyle} required
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton onClick={handleClickShowPassword}>
                                                {userData.showPassword ? <VisibilityOff /> : <Visibility />}
                                            </IconButton>
                                        ),
                                    }}
                                />
                                
                            </>}
                            
                            {step === 1 && <>
                                <TextField name="companyName" fullWidth margin="normal" label="Company Name" variant="filled" value={userData.companyName} onChange={handleChange} sx={inputStyle} required />
                                <TextField name="designation" fullWidth margin="normal" label="Designation" variant="filled" value={userData.designation} onChange={handleChange} sx={inputStyle} required />
                                <TextField name="phone" fullWidth margin="normal" label="Phone Number" variant="filled" value={userData.phone} onChange={handleChange} sx={inputStyle} />
                                <TextField name="location" fullWidth margin="normal" label="Location" variant="filled" value={userData.location} onChange={handleChange} sx={inputStyle} required />
                                <TextField name="zipcode" fullWidth margin="normal" label="Zipcode" variant="filled" value={userData.zipcode} onChange={handleChange} sx={inputStyle} required />
                            </>}

                            {step === 2 && <>
                                <Typography variant="h6" align="center" gutterBottom sx={{ color: '#fff', fontWeight: 'Light', marginBottom: '20px' }}>
                                {otpGenerate ? 'OTP generated successfully! ' : 'Your account registration was successful! Please enter the One-Time Password sent to your email to proceed further.'}
                                </Typography>
                                <TextField
                                    name="otp"
                                    fullWidth
                                    margin="normal"
                                    label="OTP"
                                    variant="filled"
                                    type={otpVisible ? 'text' : 'password'}
                                    value={otp}
                                    onChange={handleOtpChange}
                                    sx={otpInputStyle}
                                    InputProps={{
                                        endAdornment: (
                                            <InputAdornment position="end">
                                                <IconButton
                                                    aria-label="toggle password visibility"
                                                    onClick={toggleOtpVisibility}
                                                    edge="end"
                                                >
                                                    {otpVisible ? <VisibilityOff /> : <Visibility />}
                                                </IconButton>
                                            </InputAdornment>
                                        ),
                                    }}
                                    required
                                />
                                <Box sx={regenerateOTPBoxStyle}>
                                <Button variant="contained" onClick={() => {regenerateOTP();setOTPGenerate(true)}} sx={verifyButtonStyle}>
                                    Regenerate OTP
                                </Button>
                                <Button variant="contained" onClick={() => {handleVerifyOtp()}} sx={verifyButtonStyle}>
                                    Verify
                                </Button>
                                </Box>
                            </>}
                            {errorMessage && <Typography color="error"align='center' sx={errorMessageTextStyle}>{errorMessage}</Typography>}
                        </Paper>
                      
                        </div>
                        </div>
                    </Fade>
                </Grid>
            </Grid>
            {step === 2 && (
                <Typography variant="body2" align="center" sx={homepageTextStyle}>
                    <RouterLink to="/" style={routerStyle}>
                    Get to Homepage
                </RouterLink>
            </Typography>
            )}
            <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
        </Box>
        
    );
};

export default RegisterPage;
