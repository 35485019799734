import React, { useState } from 'react';
import { 
    Button, TextField, Grid, Paper, Typography, Box, Divider, 
    Fade, IconButton, Dialog, DialogTitle, DialogContent, 
    CircularProgress 
} from '@mui/material';
import MailOutlineIcon from '@mui/icons-material/MailOutline';
import ArrowBackIosIcon from '@mui/icons-material/ArrowBackIos';
import { useNavigate } from 'react-router-dom';
import AlertComponent from './subcomponents/AlertComponent';
import { forgotPassword } from '../services/Endpoints';
import { boxStyle, button1Style, dialogContentStyle, dividerStyle, gridStyle, mailOutlineIconStyle, paperStyle, textFieldStyle, textStyle } from '../constants/ForgotPasswordConst';

const ForgotPassword: React.FC = () => {
  const [email, setEmail] = useState('');
  const [openDialog, setOpenDialog] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState('');
  const [keyMessage, setKeyMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorType, setErrorType] = useState<string>('');
  const navigate = useNavigate();

  const sendPasswordResetLink = async (email: string) => {
    try {
      const response = await forgotPassword(email);
      const data = await response.json();
      setSnackbarMessage('Reset link sent. Please check your email.');
      return response.status === 200 
    } catch (error) {
      console.error('Error sending reset link:', error);
      setSnackbarMessage('Failed to send reset link. Please try again.');
    } finally {
      setOpenDialog(false);
    }
  };

  const handleBackClick = () => {
    navigate('/login');
  };

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmail(event.target.value);
  };

  const handleSendResetLink = async () => {
    if (email === '') {
      setSnackbarMessage('Please enter a valid email address.');
      return;
    }
    setOpenDialog(true);
    const checkStatus = sendPasswordResetLink(email)
    await checkStatus && navigate('/reset-password', { state: { email : email } }) 
  };
  

  return (
    <Box 
      sx={boxStyle}
    >
      <Grid container alignItems="center" justifyContent="center" style={gridStyle}>
        <Fade in={true} timeout={1000}>
          <Paper 
            elevation={10} 
            sx={paperStyle}
          >
            <Grid container alignItems="center" spacing={2}>
              <Grid item xs={2}>
                <IconButton color="primary" size="large" onClick={handleBackClick}>
                  <ArrowBackIosIcon />
                </IconButton>
              </Grid>
              <Grid item xs={8}>
                <Typography variant="h4" align="center" gutterBottom sx={textStyle}>
                  Forgot Password
                </Typography>
              </Grid>
            </Grid>
            <Divider sx={dividerStyle} />
            <Typography variant="body1" align="center" gutterBottom sx={textStyle}>
             No worries. Give us your email and we will help you set a new one.
            </Typography>
            <TextField 
              name="email"
              fullWidth 
              margin="normal" 
              label="Email Address" 
              variant="filled" 
              value={email} 
              onChange={handleChange} 
              
              sx={textFieldStyle}
              InputProps={{
                startAdornment: (
                  <MailOutlineIcon color="action" sx={mailOutlineIconStyle} />
                )
              }}
            />
            <Button 
              fullWidth 
              variant="contained" 
              color="primary" 
              sx={button1Style}
              onClick={handleSendResetLink}
            >
               Reset Password
            </Button>
          </Paper>
        </Fade>
      </Grid>

      <Dialog open={openDialog} onClose={() => setOpenDialog(false)}>
        <DialogTitle>
          Sending Reset Link...
        </DialogTitle>
        <DialogContent sx={dialogContentStyle}>
          <CircularProgress />
        </DialogContent>
      </Dialog>
      <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
    </Box>
  );
};

export default ForgotPassword;
