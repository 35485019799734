import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, useMediaQuery, Button, Pagination, CssBaseline, FormGroup, FormControlLabel, Checkbox } from '@mui/material';
import { ThemeProvider, styled } from '@mui/material/styles';
import { darkTheme , lightTheme } from './subcomponents/themes';
import 'react-image-lightbox/style.css';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import 'react-lazy-load-image-component/src/effects/blur.css';
import { fuzzySearch } from './subcomponents/fuzzySearch';
import { useLocation , useNavigate } from 'react-router-dom';
import { NoDataPage } from './subcomponents/NoData';
import { PreLoader } from './subcomponents/preloader';
import '../styles/viewImages.css';
import SelectAllIcon from '@mui/icons-material/SelectAll';
import { DemoDatasets } from '../constants/demoDatasets';
import DeselectIcon from '@mui/icons-material/Deselect';
import DeleteIcon from '@mui/icons-material/Delete';
import SellIcon from '@mui/icons-material/Sell';
import Swal from 'sweetalert2';
import { getImages , setTagsPOST , deleteImagesPOST , getEnlargedImage , deleteAllImages, getSimilarImages, updateImageCategoryPOST } from '../services/Endpoints';
import { boxStyle, buttonColor, checkboxFilterStyle, imageHighlightedStyle , imageText, navButtonStyle, noFiltersStyle, paginationStyle, selectedImageStyle } from '../constants/ViewImagesConst';
import CollectionsIcon from '@mui/icons-material/Collections';
import AlertComponent from './subcomponents/AlertComponent';
import TutorialComponent from './subcomponents/DemoTour';
import { ImageData , FiltersData } from '../common/types';
import { EnlargedViewComponent } from './subcomponents/enlargedViewComponent';
import { extractImageFilename } from '../common/ExtractImageFileName';

const ImagePaper = styled(Paper)({
  cursor: 'pointer',
  position: 'relative',
  overflow: 'hidden',
  '&:hover': {
    opacity: 0.8,
  },
});

const ViewImages: React.FC = () => {
  const [images, setImages] = useState<ImageData[]>([]);
  const [pageCount, setPageCount] = useState<number>(1);
  const [preloader, setPreloader] = useState<boolean | null>(true);
  const [paginationCount, setPaginationCount] = useState<number>(1);
  const [openLightbox, setOpenLightbox] = useState<boolean>(false);
  const [currentImage, setCurrentImage] = useState<number>(0);
  const [darkMode, setDarkMode] = useState<boolean>(false);
  const isMobile = useMediaQuery('(max-width:600px)');
  const [imageFilters , setImageFilters] = useState<boolean>(false);
  const [imageURL , setImageURL] = useState<{ [key: string]: string[] }>({});
  const [imageClicked , setImageClicked] = useState<{ [page: number]: boolean[] }>({});
  const [categoryFilters, setCategoryFilters] = useState<FiltersData[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<{ [key: string]: string[] }>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [similarImagesClicked , setSimilarImagesClicked] = useState<boolean>(false);
  const [filteredCategoryFilters, setFilteredCategoryFilters] = useState<FiltersData[]>([]);
  const [enlargedImage, setEnlargedImage] = useState<string>('');
  const [loader, setLoader] = useState<boolean>(false);
  const [openSnackbar , setOpenSnackbar] = useState<boolean>(false);
  const [keyMessage , setKeyMessage] = useState<string>('');
  const [errorType , setErrorType] = useState<string>("");
  const [imageDetails, setImageDetails] = useState<{ [key: string]: string[] }>({});
  const [selectedImageIndex, setSelectedImageIndex] = useState<number | null>(null);
  const location = useLocation();
  const navigate = useNavigate();
  const datasetId = location.state?.datasetId;
  const auditIssues = location.state?.auditIssues;
  const fetchImages = async (page:number) => {
    const config = {
      headers: {
        'x-api-key': `${localStorage.getItem('api-key')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "id": `${datasetId}`,
        "pageNum": page,
        "imageQty": 15,
        ...selectedFilters,
        "outlier" : auditIssues!== null
      }),
      method: 'POST',
    };
    if (page <= pageCount && page > 0) {
      setLoader(true);
      await getImages(config)
        .then((response) => response.json())
        .then((data) => {
          const imageData = data.imageURLs.map((image: any) => ({
            url: image,
            title: ''
          }));
          setImages(imageData);
          if (data.uniqueTags !== undefined) {
            const filtersData: FiltersData[] = [
              { uniqueTags: data.uniqueTags || [], uniqueCategory: [] , uniqueSubCategory:[] },
              { uniqueCategory: data.uniqueCategory || [], uniqueTags: [] , uniqueSubCategory:[] },
              { uniqueSubCategory: data.uniqueSubCategory , uniqueCategory: [] || [], uniqueTags: [] }
            ];
            setCategoryFilters(filtersData);
          }
          if (!imageClicked[page]) {
            setImageClicked((prevState) => ({
              ...prevState,
              [page]: Array(imageData.length).fill(false),
            }));
          }
          setPageCount(data.totalPages || 1);
        })
        .catch((err) => {
          setErrorType('error')
          setOpenSnackbar(true);
          setKeyMessage(err.message);
        })
        .finally(() => {
          setPreloader(false);
          setLoader(false);
        });
    }
  };

  const deleteImagesByFilter = () => {
    if(Object.keys(selectedFilters).length === 0)
    { 
      setErrorType('error')
      setOpenSnackbar(true);
      setKeyMessage('Select an category or an quality issue to delete images');
    }
    else
    {
      const payload=JSON.stringify({
        ...selectedFilters
      });
      deleteAllImages(datasetId,payload,'DELETE')
        .then((response) => response.json())
        .then((data) => {
          setOpenSnackbar(true);
          setKeyMessage(data.message);
          setTimeout(() => {
            window.location.reload();
          }, 3000);
        })
        .catch((err) => {
          setErrorType('error')
          setOpenSnackbar(true);
          setKeyMessage(err.message);
        })
    }
  }

  useEffect(() => {
    setImageFilters(DemoDatasets.includes(datasetId)) 
  },[])

  useEffect(() => {
    ((selectedImageIndex !== null && images[selectedImageIndex]?.url) && !imageFilters && similarImagesClicked) ? similarImages() : fetchImages(paginationCount)
  }, [paginationCount, selectedFilters]);

  useEffect(() => {
    !location.state &&
      navigate('/dashboard') ;
      return;
  }, [location, navigate]);

  useEffect(() => {
    setImageClicked([]);setImageURL({});setSimilarImagesClicked(false)
  },[!imageFilters])


  useEffect(() => {
    if (searchTerm) {
      let uniqueCategoryFilter: string[] = [];
      let uniqueSubCategoryFilter:string[] = [];
      let uniqueTagsFilter: string[] = [];
      let options = {
        threshold: 0.3
      };

      categoryFilters.forEach(({ uniqueCategory, uniqueTags , uniqueSubCategory }) => {
        if (uniqueCategory && uniqueCategory.length > 0) {
          uniqueCategoryFilter = fuzzySearch(uniqueCategory, options, searchTerm)
        } else if (uniqueTags && uniqueTags.length > 0) {
          uniqueTagsFilter = fuzzySearch(uniqueTags, options, searchTerm)
        }
        else if(uniqueSubCategory && uniqueSubCategory.length>0)
        {
          uniqueSubCategoryFilter = fuzzySearch(uniqueSubCategory , options , searchTerm)
        }
      });
      uniqueCategoryFilter.length === 0 && uniqueTagsFilter.length === 0 && uniqueSubCategoryFilter.length === 0 ? setFilteredCategoryFilters([]) : setFilteredCategoryFilters([{ uniqueCategory: uniqueCategoryFilter, uniqueTags: uniqueTagsFilter , uniqueSubCategory: uniqueSubCategoryFilter }]);
    } else {
      setFilteredCategoryFilters(categoryFilters);
    }
  }, [searchTerm, categoryFilters]);

  const checkSelectedImage = () => {
    const selected = Object.values(imageClicked).flat().some((clicked) => clicked);
    if (!selected) {
      Swal.fire({
        title: "Images",
        background: 'black',
        color: 'white',
        text: 'No Images Selected',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#f79009',
        showCancelButton: false,
        showCloseButton: false,
      })
      return false;
    }
    else {
      return true;
    }
  }

  const updateImageClickedState = (pageIndex: number, imageIndex: number, value: any) => {
    setImageClicked((prevState) => {
      const newState = { ...prevState };
      if (!newState[pageIndex]) {
        newState[pageIndex] = Array(images.length).fill(false);
      }
      newState[pageIndex][imageIndex] = value;
      return newState;
    });
  };

  const getImageURLs = () => {
    return Object.values(imageURL).flatMap(data => data);
  }  

  const tagImages = () => {
    if (checkSelectedImage()) {
      Swal.fire({
        title: 'Add Tags',
        background: 'black',
        color: 'white',
        html: `
        <div class="tagImagesDiv">
          <input class="tagInput" id="tags" placeholder="Enter Tag">
        </div>
      `,
        confirmButtonText: 'Add Tags',
        confirmButtonColor: '#f79009',
        showCancelButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const tags = (document.getElementById('tags') as HTMLInputElement).value;
          const payload=JSON.stringify({
            tags: tags,
            images: getImageURLs(),
          });
          setTagsPOST(datasetId,payload,'PUT')
            .then((response) => response.json())
            .then((data) => {
              setErrorType('success');
              setOpenSnackbar(true);
              setKeyMessage(data.message);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            })
            .catch((error) => {
              setErrorType('error');
              setOpenSnackbar(true);
              setKeyMessage(error.message);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            });
        }
      });
    }
  };

  const updateDropdownFilter = () => {
    const dropdownCategories:any = [];
    categoryFilters[1].uniqueCategory.map((data,index) => {
      dropdownCategories.push(`<option value="${data}">${data}</option>`)
    })
    dropdownCategories.push(`<option value="other">Other</option>`)
    return dropdownCategories
  }

  const updateImageCategory = () => {
    if (checkSelectedImage()) {
      Swal.fire({
        title: 'Update Image Category',
        background: 'black',
        color: 'white',
        html: `
        <div class="tagImagesDiv">
          <label>Choose New Category</label>  
          <select name="newCategory" id="newCategory">
            ${updateDropdownFilter().toString().split(',').join("")}
          </select>
          <input class="tagInput" id="imageCategoryInput" placeholder="Enter New Category">
        </div>
      `,
        confirmButtonText: 'Update Category',
        confirmButtonColor: '#f79009',
        showCancelButton: true,
        showCloseButton: true,
        didOpen: () => {
          const newCategoryElement = document.getElementById('newCategory') as HTMLSelectElement;
          const imageCategoryInputElement = document.getElementById('imageCategoryInput') as HTMLElement;
      
          if (newCategoryElement && imageCategoryInputElement) {
              newCategoryElement.addEventListener('change', function() {
                  if (newCategoryElement.value === 'other') {
                      imageCategoryInputElement.style.display = 'block';
                  } else {
                      imageCategoryInputElement.style.display = 'none'; // Hide it when other values are selected
                  }
              });
          }     
      },
      }).then(async (result) => {
        if (result.isConfirmed) {
          const newCategoryElement = document.getElementById('newCategory') as HTMLSelectElement;
          const imageCategoryInputElement = document.getElementById('imageCategoryInput') as HTMLInputElement;
          const newCategory = newCategoryElement.value === 'other' ? imageCategoryInputElement.value : newCategoryElement.value;
          try{
            let response = await getEnlargedImage(datasetId,extractImageFilename(getImageURLs()[0]))
            let data = await response.json();
            const payload = JSON.stringify({
              currentCategory : data.attributes.category[0],
              newCategory : newCategory,
              images : getImageURLs(),
            });
            updateImageCategoryPOST(datasetId, payload, 'PUT')
              .then((response) => response.json())
              .then((data) => {
                setErrorType('success');
                setOpenSnackbar(true);
                setKeyMessage(data.message);
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              })
              .catch((error) => {
                setErrorType('error');
                setOpenSnackbar(true);
                setKeyMessage(error.message);
                setTimeout(() => {
                  window.location.reload();
                }, 3000);
              });
          }
          catch(err:any)
          {
            setErrorType('error');
            setOpenSnackbar(true);
            setKeyMessage(err.message);
          }
        }
      });
    }
  }

  const checkImageSelection = (page:number) => {
    return imageClicked[page]?.every((ele) => ele === true);
  }

  const handleSelectAllImages = () => {
    const updatedClickedState = { ...imageClicked, [paginationCount]: Array(images.length).fill(true) };
    const updatedURLState = {
      ...imageURL,
      [paginationCount] : images.map((image) => extractImageFilename(image.url)).filter(Boolean),
    };

    setImageClicked(updatedClickedState);
    setImageURL(updatedURLState);
  };

  const deleteImages = () => {
    if (checkSelectedImage()) {
      Swal.fire({
        title: 'Delete Images',
        background: 'black',
        color: 'white',
        text: 'Are you sure to delete these images?',
        confirmButtonText: 'Delete Images',
        confirmButtonColor: '#f79009',
        showCancelButton: true,
        showCloseButton: true,
      }).then((result) => {
        if (result.isConfirmed) {
          const payload = JSON.stringify({
            datasetId: `${datasetId}`,
            imageNames: getImageURLs(),
          });
          deleteImagesPOST(datasetId,payload,'DELETE')
            .then((response) => response.json())
            .then((data) => {
              setOpenSnackbar(true);
              setKeyMessage(data.message);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            })
            .catch((err) => {
              setErrorType('error');
              setOpenSnackbar(true);
              setKeyMessage(err.message);
              setTimeout(() => {
                window.location.reload();
              }, 3000);
            });
        }
      });
    }
  };

  const similarImages = () => {
    let trueCount:any = 0;
    if(Object.keys(imageClicked).length > 0)
    {
      const values =  Object.values(imageClicked[paginationCount]) as unknown as boolean[];
      values.map((value) => {
        value === true && trueCount++
      })
    }
    if (trueCount !== 1) {
      Swal.fire({
        title: "Images",
        background: 'black',
        color: 'white',
        text: 'Select one image to view similar images',
        confirmButtonText: 'Ok',
        confirmButtonColor: '#f79009',
        showCancelButton: false,
        showCloseButton: false,
      })
    }
    else
    { 
      
      Swal.fire({
        title: "Similar Images",
        background:'black',
        color:'white',
        html: `
          <div class="similarImagesDiv">
            <label>Provide the threshold to get similar images</label>  
            <input class="inputRange" type="range" id="thresholdSlider" min="0" step="0.1" max="1.0" list="markers">
            <datalist id="markers">
              <option value="0.1" label="0.1"></option>
              <option value="0.2" label="0.2"></option>
              <option value="0.3" label="0.3"></option>
              <option value="0.4" label="0.4"></option>
              <option value="0.5" label="0.5"></option>
              <option value="0.6" label="0.6"></option>
              <option value="0.7" label="0.7"></option>
              <option value="0.8" label="0.8"></option>
              <option value="0.9" label="0.9"></option>
              <option value="1.0" label="1.0"></option>
            </datalist>
            <input type="text" class="inputText" id="thresholdValue" placeholder="Threshold Value" />
          </div>
        `,
        showConfirmButton:true,
        confirmButtonText:'Get Similar Images',
        confirmButtonColor:'#f79009',
        showCancelButton: true,
        showCloseButton: true,
        didOpen: () => {
          const input:any = document.querySelector("#thresholdSlider");
          const value: any = document.querySelector("#thresholdValue");
          value.value = input.value;
          input.addEventListener("input", (event:any) => {
            value.value = event.target.value;
          });
          value.addEventListener("input" , (event:any) => {
            if(event.target.value < 0 && event.target.value >1)
            {
              alert('Enter values ranging from 0 to 1.0');
            }
            else
            {
              input.value = event.target.value
            }
          })
        },
        willClose: () => {
        }
      })
      .then((result) => {
        if(result.isConfirmed)
        {
          const thresholdValue = Number((document.getElementById('thresholdValue') as HTMLInputElement).value);
          setLoader(true);
          getSimilarImages(datasetId , Object.values(imageURL)[0][0],paginationCount,15,thresholdValue)
          .then((response) => response.json())
          .then((data) => {
            setImages([]);
            const imageData = data.imageURLs.map((image: any) => ({
              url: image,
              title: ''
            }));
            setImages(imageData);
            setPageCount(data.totalPages);
            setSimilarImagesClicked(true);
          })
          .catch((err) => {
            setErrorType('error');
            setOpenSnackbar(true);
            setKeyMessage(err.message);
            setTimeout(() => {
              window.location.reload();
            },2000)
          })
          .finally(() => {
            setLoader(false);
          })
        }
      })
    }
  };


  const handleImageClick = async (pageIndex: number, index: number) => {
    setCurrentImage(index);
    setSelectedImageIndex(index);
    if(images[index] === undefined && paginationCount !== pageCount)
    {
      // await fetchImages(pageIndex+1);
      // handleImageClick(paginationCount,0);
      setOpenLightbox(false);
    }
    else
    {
      const fileName: string | null = extractImageFilename(images[index].url);
      if (!imageFilters) {
        setEnlargedImage('');
        try {
          let response = await getEnlargedImage(datasetId, fileName)
          let data = await response.json()
          setEnlargedImage(data.url);
          setImageDetails(data.attributes);
          setOpenLightbox(true)
        }
        catch (err: any) {
          setErrorType('error');
          setOpenSnackbar(true);
          setKeyMessage(err.message);
        }
      } else {
        const newImageClickedState = !imageClicked[pageIndex]?.[index];
        updateImageClickedState(pageIndex, index, !imageClicked[pageIndex]?.[index]);
        setImageURL((prevState) => {
          let state = { ...prevState };
          if (newImageClickedState) {
            if (state[paginationCount] === undefined) {
              state[paginationCount] = []
            }
            if (fileName && !state[paginationCount].includes(fileName)) {
              state[paginationCount].push(fileName)
            }
            return fileName ? state : prevState;
          } else {
            const updatedURLs = Object.values(prevState[paginationCount]).filter((url) => url !== fileName);
            state[paginationCount] = updatedURLs
            return { ...state }
          }
        });
      }
    }
  };

  const handleCheckboxClick = (data: string, type: string) => {
    const filterArray: { [key: string]: string[] } = { ...selectedFilters };
    if (!filterArray.hasOwnProperty(type)) {
      filterArray[type] = [data];
    } else {
      const index = filterArray[type].indexOf(data);
      if (index === -1) {
        filterArray[type].push(data);
      } else {
        filterArray[type].splice(index, 1);
      }
    }
    setSelectedFilters({ ...filterArray });
    setImageURL({});
    setImageClicked([]);
    setPaginationCount(1);
  };

  const viewEnlargedImage = async (url: string) => {
    setOpenLightbox(true);
    setEnlargedImage(url);
  };

  const handleCheckboxToggle = (category: string, selectedFilters: { [key: string]: string[] }) => {
    if (category) {
      for (const key in selectedFilters) {
        if (selectedFilters.hasOwnProperty(key)) {
          const filters = selectedFilters[key];
          if (Array.isArray(filters) && filters.includes(category)) {
            return true;
          }
        }
      }
    }
    return false;
  };

  const toggleDarkMode = () => {
    setDarkMode(!darkMode);
  };

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <CssBaseline />
      {preloader ? (
        <PreLoader preloaderCheck={preloader} />
      ) : (
        <div>
          {filteredCategoryFilters.length === 0 && images.length === 0 ? (
            <NoDataPage displayText={'Lost in space, no images available'} />
          ) : (
            <>
              <div className='searchFilterDiv'>
                <input
                  className='searchFilterInput'
                  onChange={(e) => setSearchTerm(e.target.value)}
                  placeholder='Search'
                  value={searchTerm}
                />
                <Button sx={navButtonStyle} onClick={() => setImageFilters(!imageFilters)}>
                  {!imageFilters ? 'Select Multiple Images' : 'Unselect Images'}
                </Button>
              </div>
                <div className='imageFilterNavbar' style={{ display: imageFilters ? 'flex' : 'none' }}>
                  {!(checkImageSelection(paginationCount)) && (
                    <Button onClick={handleSelectAllImages}>
                      <SelectAllIcon sx={buttonColor} />
                    Select All
                  </Button>
                  )}
                  <Button
                    onClick={() => {
                      setImageClicked({ ...imageClicked, [paginationCount]: Array(images.length).fill(false) });
                      setImageURL({...imageURL , [paginationCount] : []});
                    }}
                  >
                    <DeselectIcon sx={buttonColor} />
                    Deselect All
                  </Button>
                  <Button onClick={deleteImages}>
                    <DeleteIcon sx={buttonColor} /> Delete Images
                  </Button>
                  <Button onClick={deleteImagesByFilter}>
                    <DeleteIcon sx={buttonColor} /> Delete Images by Category
                  </Button>
                  <Button onClick={tagImages}>
                    <SellIcon sx={buttonColor} /> Tag Images
                  </Button>
                  <Button onClick={updateImageCategory}>
                    <SellIcon sx={buttonColor} /> Update Image Category
                  </Button>
                  {!similarImagesClicked ? (
                    <Button onClick={() => {similarImages()}}>
                    <CollectionsIcon sx={buttonColor} /> Similar Images
                  </Button>
                  ) : (
                    <>
                    <Button onClick={() => {similarImages()}}>
                      <CollectionsIcon sx={buttonColor} /> Similar Images
                    </Button>
                    <Button onClick={() => {window.location.reload()}}>
                      <CollectionsIcon sx={buttonColor} /> Original Images
                    </Button>
                    </>
                  )}
                </div>
              <div className='filterNavbar'>
                <Button
                  sx={navButtonStyle}
                  onClick={() => {
                    setSelectedFilters({});
                    setSearchTerm('');
                  }}
                >
                  Reset Filters
                </Button>

                <input
                  type='number'
                  className='pageNumberFilterInput'
                  onChange={(e) => setPaginationCount(Number(e.target.value))}
                  value={paginationCount === 0 ? 'Enter page number' : paginationCount}
                />
              </div>
              <div className='mainDiv'>
                <TutorialComponent type="viewImages"/>
                <div className='filtersDiv'>
                  {filteredCategoryFilters.length === 0 ? (
                    <div className='noFiltersDiv'>
                      <Typography
                        variant='caption'
                        sx={noFiltersStyle}
                      >
                        No Results Available
                      </Typography>
                    </div>
                  ) : (
                    filteredCategoryFilters.map((filter, key) => (
                      <div className='listFilterDiv' key={key}>
                        {Object.entries(filter).map(
                          ([categoryType, categories]) =>
                            categories.length !== 0 && (
                              <div key={categoryType}>
                                <h6 className='categoryTypeHeader'>
                                  {categoryType === 'uniqueTags' ? 'Quality Issues' : categoryType === 'uniqueCategory' ? 'Categories' : 'Sub Categories'}
                                </h6>
                                <FormGroup>
                                  {categories.map((category: string, index: number) => (
                                    <FormControlLabel
                                      key={index}
                                      control={
                                        <Checkbox
                                          checked={handleCheckboxToggle(category, selectedFilters)}
                                          onClick={() => handleCheckboxClick(category, categoryType)}
                                          sx={checkboxFilterStyle}
                                        />
                                      }
                                      label={
                                        category === null
                                          ? ''
                                          : category
                                              .split(' ')
                                              .map(
                                                (cate: string) =>
                                                  cate.slice(0, 1).toUpperCase() + cate.slice(1)
                                              )
                                              .join('')
                                      }
                                      sx={checkboxFilterStyle}
                                    />
                                  ))}
                                </FormGroup>
                              </div>
                            )
                        )}
                      </div>
                    ))
                  )}
                </div>
                <Box style={boxStyle}>
                  {loader ? (
                    <PreLoader preloaderCheck={loader} />
                  ) : images.length > 0 ? (
                    <Grid className='imagesList' container spacing={isMobile ? 1 : 2}>
                      {images.map((image, index) => (
                        <Grid
                          item
                          xs={12}
                          sm={6}
                          md={3}
                          key={index}
                          onClick={() => handleImageClick(paginationCount, index)}
                          // sx={imageClicked[paginationCount]?.[index] ? imageHighlightedStyle : undefined}
                        >
                          <ImagePaper elevation={3}>
                            <LazyLoadImage
                              alt={image.title}
                              src={image.url}
                              effect='blur'
                              width='100%'
                              height='200px'
                              style={imageClicked[paginationCount]?.[index] ? selectedImageStyle : undefined}
                            />
                            <Typography
                              variant='caption'
                              sx={imageText}
                            >
                              {image.title}
                            </Typography>
                          </ImagePaper>
                        </Grid>
                      ))}
                    </Grid>
                  ) : (
                    <NoDataPage displayText={'Lost in space, no images available'} />
                  )}
                  <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 4 }}>
                    <Pagination
                      count={pageCount}
                      page={paginationCount}
                      shape='circular'
                      onChange={(event, page) => {
                        setPaginationCount(page);
                      }}
                      color={'primary'}
                      sx={paginationStyle}
                    />
                  </Box>
                </Box>
              </div>
            </>
          )}
        </div>
      )}
      <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
      {selectedImageIndex !== null && images[selectedImageIndex]?.url && (
           <EnlargedViewComponent 
            openLightbox={openLightbox}
            enlargedImage={enlargedImage}
            originalImage={images[selectedImageIndex].url}
            imageDetails={imageDetails}
            onClose={() => setOpenLightbox(false)}
            previousImage={() => handleImageClick(paginationCount,selectedImageIndex-1)}
            nextImage={() => handleImageClick(paginationCount,selectedImageIndex+1)}
          />
      )}
    </ThemeProvider>
  );
};

export default ViewImages;
