import React, { useEffect, useState } from 'react';
import { ReactTabulator, ColumnDefinition, ReactTabulatorOptions } from 'react-tabulator';
import 'react-tabulator/css/tabulator.min.css';
import 'react-tabulator/css/tabulator_midnight.min.css';
import '../styles/DataAudit.css';
import { useLocation, useNavigate } from 'react-router-dom';
import { deleteImagesPOST, getDataAuditDetails, getDuplicateCluster, updateDataAuditStatusPOST } from '../services/Endpoints';
import { PreLoader } from './subcomponents/preloader';
import { NoDataPage } from './subcomponents/NoData';
import { Button, Typography } from '@mui/material';
import Swal from 'sweetalert2';
import AlertComponent from './subcomponents/AlertComponent';
import TutorialComponent from './subcomponents/DemoTour';
import { buttonColor } from '../constants/ViewImagesConst';
import { Incident , CellData , ImageCluster } from '../common/types';
import CalculateIcon from '@mui/icons-material/Calculate';
import { GetThresholdDetails } from './subcomponents/CalculateThresholdDetails';
import { extractImageFilename } from '../common/ExtractImageFileName';

const options: ReactTabulatorOptions = {
  pagination: true,
  height: 'max-content',
  paginationMode: 'local',
  width: '100%',
  paginationSize: 10,
  movableColumns: true,
  layout: 'fitColumns',
  responsiveLayout: true
};

const IncidentTable: React.FC = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [incidents, setIncidents] = useState<Incident[]>([]);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [keyMessage, setKeyMessage] = useState<string>('');
  const [preloader, setPreloader] = useState<boolean>(true);
  const [errorType , setErrorType] = useState<string>('');
  const [calculateThreshold,setCalculateThreshold] = useState<boolean>(false);
  const datasetId = location.state?.datasetId;
  let totalPages:{[key:string] : number} = {};
  const pageSize = 10;
  let currentPage:{[key:string] : number} = {};
  let clusterImages:ImageCluster = {paginatedClusters: {},totalPages:0};
  useEffect(() => {
    if (!location.state) {
      navigate('/dashboard');
      return;
    }
    const { datasetId } = location.state;
    fetchDataAudits(datasetId)
    
  }, [location, navigate]);

  if (!location.state) {
    return null;
  }

  const fetchDataAudits = async (datasetId:string) => {
    try{
      let response = await getDataAuditDetails(datasetId)
      let data:any = await response.json() 
      data.results && setIncidents(data.results);
      setPreloader(false);
    }
    catch(err:any){
      setOpenSnackbar(true);
      setKeyMessage(err.message);
      setErrorType('error');
      setPreloader(false);
    }
  }

  const fetchImageClusters = async (type:string,page:number) => {
    try {
      const response = await getDuplicateCluster(datasetId,page,pageSize,type);
      const data = await response.json();
      return data;
    } catch (err:any) {
      setOpenSnackbar(true);
      setKeyMessage(err.message);
      setErrorType('error');
    }
  };

  const mergeDuplicateImages = async (imageCluster:any , index:number) =>  {
    let deleteIndex = Object.keys(imageCluster.paginatedClusters)[index]
    const imageData:any = [];
    imageCluster.paginatedClusters[deleteIndex].duplicates.map((data:any,index:number) => {
      imageData.push(extractImageFilename(data)) 
    })
    const payload = JSON.stringify({
      datasetId: `${datasetId}`,
      imageNames: imageData,
    });

    Swal.fire({
      title: "Merge Images",
      background: 'black',
      color: 'white',
      text : `Do you want to merge the images`,
      showConfirmButton: true,
      confirmButtonText:'Yes',
      confirmButtonColor:'#f79009',
      showCancelButton: true,
      showCloseButton: true,
    })
    .then(async (result) => {
      if(result.isConfirmed)
      {
        try {
          const response = await deleteImagesPOST(datasetId,payload,'DELETE')
          const data = await response.json();
          setOpenSnackbar(true);
          setKeyMessage('Duplicates removed successfully !');
          setErrorType('success');
          setTimeout(() => {
            window.location.reload();
          },3000)
        } catch (err:any) {
          setOpenSnackbar(true);
          setKeyMessage(err.message);
          setErrorType('error');
        }
      }
    })
  }

  const noImageURL = 'https://i.seadn.io/gae/OGpebYaykwlc8Tbk-oGxtxuv8HysLYKqw-FurtYql2UBd_q_-ENAwDY82PkbNB68aTkCINn6tOhpA8pF5SAewC2auZ_44Q77PcOo870?auto=format&dpr=1&w=3840';
  const { datasetName } = location.state;

  const getClusterImages = (clusterImages:ImageCluster,issueType:string) => {
    const clusterExpand = document.createElement('div');
    clusterExpand.setAttribute('class','imagesSection');
    Object.values(clusterImages.paginatedClusters).map((value: any,index:number) => {
      clusterExpand.innerHTML += `
    <div class="imageClusterDiv">
      <div class="originalImageDiv">
        <p>Original Image</p>
        <img class="imageCluster" src="${value?.original[0] === undefined ? noImageURL : value?.original[0]}">
      </div>
      <div class="duplicateImagesDiv">
        <p>${issueType === 'near_duplicate' ? 'Near Duplicate' : 'Duplicate'} Image</p> 
        <div class="duplicateImageView">
          <img class="imageCluster" src="${value?.duplicates[0] === undefined ? noImageURL : value?.duplicates[0]}">
          ${value?.duplicates[1] !== undefined ? `<img class="imageCluster" src="${value?.duplicates[1]}">` : ``}
          ${value?.duplicates[2] !== undefined ? `<img class="imageCluster" src="${value?.duplicates[2]}">`: ``}
        </div>
      </div>
      <div class="mergeImagesButtonDiv">
        <button class="actionButton merge_${index}" id="mergeImagesButton">Merge Images</button>
      </div>
      </div>
    </div>
  `;
    })
    return clusterExpand;
  }

  const getIssueType = (data:any) => {
    return data.toLowerCase() === 'near duplicate' ? 'near_duplicate' : 'duplicate';
  }

  const checkNoImage = (imagesSection:any) => {
    let noImagesDiv = document.createElement('div');
    noImagesDiv.setAttribute('class','noImagesDiv');
    noImagesDiv.innerHTML = `<h1 class='noImageText' >No Images Available</h1>`
    document.querySelector('.clusterExpand')?.removeAttribute('style');
    imagesSection && imagesSection.append(noImagesDiv)
  }

  const updateImageClusters = async(imagesSection:any,row:any,paginationDiv:HTMLAreaElement) => {
    paginationDiv.innerHTML = '';
    clusterImages = await fetchImageClusters(getIssueType(row.getData().info), currentPage[`${getIssueType(row.getData().info)}`]);
    imagesSection.innerHTML = '';
    Object.values(clusterImages.paginatedClusters).length > 0 ? imagesSection.append(getClusterImages(clusterImages,getIssueType(row.getData().info))) : checkNoImage(imagesSection);
    paginationDiv.innerHTML = paginationToggleComponent(getIssueType(row.getData().info))
  }

  const buttonTriggers = (expander:any,row:any) => {
    const paginationToggle = expander.querySelector(`.paginationDiv_${getIssueType(row.getData().info)}`);
    const imagesSection = expander.querySelector('.imagesSection')
    if (paginationToggle) {
      paginationToggle.addEventListener('click', async (e: any) => {
        if (e.target.id.includes('previousPage') && currentPage[`${getIssueType(row.getData().info)}`] !== 1) {
          imagesSection.innerHTML = `<div class="loader"></div>`
          currentPage[`${getIssueType(row.getData().info)}`]--;
          updateImageClusters(imagesSection,row,paginationToggle);
        }
        else if (e.target.id.includes('nextPage') && currentPage[`${getIssueType(row.getData().info)}`] !== totalPages[`${getIssueType(row.getData().info)}`]) {
          imagesSection.innerHTML = `<div class="loader"></div>`;
          currentPage[`${getIssueType(row.getData().info)}`]++;
          updateImageClusters(imagesSection,row,paginationToggle);
        }
        else if(e.target.id.includes('pageNumber') && e.target.id!=='pageNumberInput')
        {
          if(currentPage[`${getIssueType(row.getData().info)}`] !== Number(e?.target.id.match(/\d+/)[0]))
          {
            imagesSection.innerHTML = `<div class="loader"></div>`;
            currentPage[`${getIssueType(row.getData().info)}`] = Number(e?.target.id.match(/\d+/)[0]);
            updateImageClusters(imagesSection,row,paginationToggle);
          }
        }
        else if(e.target.id === 'pageNumberInput')
        {
          paginationToggle.querySelector('.pageNumberInput').addEventListener('change' ,async (e:any) => {
            if(e?.target.value > 0 && e?.target.value<totalPages && e?.target.value !==currentPage )
            {
              imagesSection.innerHTML = `<div class="loader"></div>`;
              currentPage[`${getIssueType(row.getData().info)}`] = Number(e?.target.value);
              updateImageClusters(imagesSection,row,paginationToggle);
            }
          })
        }
      });
    }

    const mergeImages = expander.querySelector('.clusterExpand');
    if (mergeImages) {
      mergeImages.addEventListener('click', (e: any) => {
        if (e.target.className.includes('merge')) {
          let index: any = e?.target.className.match(/\d+/)[0]
          mergeDuplicateImages(clusterImages, index)
        }
      });
    }
    const exploreImagesBtn = expander.querySelector('#exploreImagesBtn');
    if (exploreImagesBtn) {
      exploreImagesBtn.addEventListener('click', () => {
        navigate('/viewimages', { state: { datasetId: datasetId, auditIssues: row.getData().info } });
      });
    }
  }

  const getPageNumbersComponent = (issueType:string) => {
    let pageComponent:any = '';
    for(let i=0;i<totalPages[issueType];i++)
    {
      if (i < 5 || (i >= (totalPages[issueType] - 5))) {
        if (currentPage[issueType] > 0 && (currentPage[issueType] - 1 === i)) {
          pageComponent += `<h6 class='pageNumbersText pageClicked actionButton' id='pageNumber${i + 1}'>${i + 1}</h6>`;
        }
        else {
          pageComponent += `<h6 class='pageNumbersText pageNotClicked actionButton' id='pageNumber${i + 1}'>${i + 1}</h6>`
        }
      }
      else if(i===5)
      {
        pageComponent +=`<input type="number" id='pageNumberInput' class='pageNumberInput' value=${currentPage[issueType]} onchange="${(e:any) => console.log(e.target.value)}"/>`
      }
    }
    return pageComponent;
  }

  const paginationToggleComponent = (info:string) => {
    return `
              <div class="paginationDiv_${info} paginationDiv">
                <button class="actionButton" id="previousPageButton">Previous</button>
                <div class="pageNumbersDiv">${getPageNumbersComponent(info)}</div>
                <button class="actionButton" id="nextPageButton">Next</button>
              </div>
            `;
  }

  const detailedInformation = (row: any) => {
    return `
            <div class="expandInfoDiv">
            <p><b>Severity:</b> ${row.getData().severity === undefined ? 'None' : row.getData().severity}</p>
            <p><b>Info:</b> ${row.getData().info}</p>
            ${['outliers'].includes(row.getData().info.toLowerCase()) ? `<button class="actionButton" id="exploreImagesBtn">Explore Images</button>` : ``}
          </div>
            `;
  }

  const columns = (): ColumnDefinition[] => [
    {
      title: '',
      formatter: (cell) => {
        const button = document.createElement('button');
        button.textContent = '+';
        button.setAttribute('class','toggleExpand');
        button.addEventListener('click', async () => {
          const row = cell.getRow();
          const rowElement = row.getElement();
          button.textContent = button.textContent === '+' ? '-' : '+';
          const clusterExpand = document.createElement('div');
          clusterExpand.className = 'clusterExpand';
          if (rowElement.classList.contains('expanded')) {
            rowElement.classList.remove('expanded');
            const expander = rowElement.querySelector('.expander');
            if (expander) {
              expander.remove();
            }
          } else {
            if (button.textContent === '-' && ['duplicate','near duplicate'].includes(row.getData().info.toLowerCase())) {
              currentPage[`${getIssueType(row.getData().info)}`] = 1;
              clusterImages = await fetchImageClusters(getIssueType(row.getData().info),currentPage[`${getIssueType(row.getData().info)}`])
              const clusterData = getClusterImages(clusterImages,getIssueType(row.getData().info));
              clusterExpand.append(clusterData);
              totalPages[`${getIssueType(row.getData().info)}`] = clusterImages.totalPages
            }
            rowElement.classList.add('expanded');
            const expander = document.createElement('div');
            expander.className = 'expander';
            expander.innerHTML += detailedInformation(row);
            if(Object.values(clusterImages.paginatedClusters).length > 0 && ['duplicate','near duplicate'].includes(row.getData().info.toLowerCase()))
            {
              clusterExpand.setAttribute('style', 'height:50vh;overflow:scroll;margin-top:50px');
              expander.append(clusterExpand);
              expander.innerHTML += paginationToggleComponent(getIssueType(row.getData().info));
            } 
            else{
              checkNoImage(expander.querySelector('.imagesSection'))
            }
            rowElement.appendChild(expander);
            buttonTriggers(expander,row);
          }
        });
        return button;
      },
      width: 70,
      hozAlign: 'center' as 'center',
      headerHozAlign:'center' as 'center'
    },
    { title: 'Issue', field: 'info', width: 400 , headerHozAlign:'center' as 'center' },
    { title: 'Impacted Number', field: 'impacted_numbers', hozAlign: 'center' as 'center' , headerHozAlign:'center' as 'center' },
    {
      title: 'Status',
      field: 'status',
      headerHozAlign:'center' as 'center',
      cellClick:function(e, cell){
        const data: CellData = cell.getData() as CellData;
        toggleStatus(data.info , data.status);
        },
    }
  ];
  
  const toggleStatus = (info:string , status:string) => {
    Swal.fire({
      title: "Update status",
      background:'black',
      color:'white',
      html: `
          <div class="updateStatus">
          <label for="options">Update the status of your issue :</label>
          <select id="taskType" name="taskType">
              <option value="fixed">Fixed</option>
              <option value="notResolved">Not Resolved</option>
              <option value="noStatus">No Status</option>
              <option value="ignore">Ignore</option>
          </select>
          </div>
      `,
      showConfirmButton:true,
      confirmButtonText:'Update your status',
      confirmButtonColor:'#f79009',
      showCancelButton: true,
      showCloseButton: true,
      didOpen: () => {
      },
      willClose: () => {
      }
    }).then((result) => {
      if(result.isConfirmed)
      {
        const taskType = (document.getElementById('taskType') as HTMLInputElement).value;
        const payload = JSON.stringify({
          issue : info,
          status : status,
        });
        updateDataAuditStatusPOST(datasetId,payload,'PUT')
        .then((response) => response.json())
        .then((data) => {
          setKeyMessage(data.message);
          setErrorType('success');
          setOpenSnackbar(true);
          window.location.reload();
        })
        .catch((err) => {
          setKeyMessage(`Something went wrong , please try again`);
          setErrorType('error');
          setOpenSnackbar(true);
        })
      } 
    });
  }
  return (
    <>
      {preloader ? (
        <PreLoader preloaderCheck={preloader} />
      ) : (
        <>
          {incidents.length > 0 ? (
            <div className="incident-table">
              <TutorialComponent type='dataaudit'/>
              <div className='calculateThresholdDiv'>
                <Button onClick={() => setCalculateThreshold(true)} sx={buttonColor}><CalculateIcon sx={buttonColor}/>Calculate Threshold</Button>
              </div>
              <Typography className='dataAuditHeader' variant='h4'>Data audit for {datasetName.toUpperCase()}</Typography>
              <ReactTabulator
                data={incidents}
                columns={columns()}
                options={options}
              />
            </div>
          ) : (
            <NoDataPage displayText={'Lost in Space, no Data Audit Information Available'} />
          )}
          <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
          {calculateThreshold && (<GetThresholdDetails datasetId={datasetId} openLoader={() => setPreloader(true)} closeLoader={() => setPreloader(false)} closePopup={() => setCalculateThreshold(false)}/> )}
        </>
      )}
    </>
  );
};

export default IncidentTable;
