import axios from 'axios';

const baseURL = 'https://server.cortalinsight.com/';

export enum Endpoints {
    Datasets = '/datasets',
    Files = '/files'
}

const backend = axios.create({ baseURL })

// Figure out a way to replace this by webpack
backend.defaults.headers.common['x-api-key'] = localStorage.getItem('api-key');

export { backend };