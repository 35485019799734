import { getUserDetails } from '../../services/Endpoints';
import { accessTokenParams } from '../../common/GetAccessToken';

export const ValidateUserPlan = async () => {
    try {
        const response = await getUserDetails(accessTokenParams);
        const data: any = await response.json();
        localStorage.setItem('Membership', data.membership_type);
        return data;
    }
    catch (err: any) {
        return err;
    }
}