import styled from "styled-components";

export const ContainerStyles = styled.div.attrs({
    className: "container-main w-screen min-w-md mx-auto"
})``;

export const NavStyles = styled.div.attrs({
    className: "banner lg:p-4 xs:p-2"
})`
    background-color: black;
    margin: 0;
`;

export const NavDivStyles = styled.div.attrs({
    className: "flex flex-wrap items-center justify-between mx-auto sm:pr-3 xs:pr-3"
})``;
export const NavDivHomeLinkStyles = styled.a.attrs({
    className: ""
})``;

export const NavDivHomeStyles = styled.span.attrs({
    className: "self-center xl:text-4xl md:text-md sm:text-lg xs:text-xl whitespace-nowrap text-white"
})``;
export const NavDivHomeLogoStyles = styled.img.attrs({
    className: "mr-1"
})``;

export const NavLinkStyles = styled.div.attrs({
    className: "items-center justify-between lg:w-auto sm:w-auto xs:w-auto md:flex md:w-auto md:order-1"
})``;

export const NavLinkUlStyles = styled.ul.attrs({
    className: "flex flex-col gap-4 p-4 sm:p-1 xs:p-0 md:p-0 lg:mt-4 md:mt-4 sm:mt-0 xs:mt-0 lg:mr-4 md:mr-4 font-medium sm:flex-row xs:flex-row md:flex-row md:space-x-8  md:border-0  sm:border-0 lg:text-xl md:text-xl sm:text-lg xs:text-lg "
})``;
export const NavLinkA1Styles = styled.a.attrs({
    className: "block py-2 pl-3 pr-4 md:hover:text-white sm:hover:text-white text-white rounded md:bg-transparent md:p-0 nav-company"
})``;

export const NavLinkA2Styles = styled.a.attrs({
    className: "block py-2 pl-3 pr-4 md:hover:text-white sm:hover:text-white text-white rounded md:bg-transparent md:p-0 nav-dash"
})``;

export const docsButtonStyle = { 
    marginLeft :'20px' ,
    marginRight: '20px',
    color: '#F79009'
}

export const demoButtonStyle = { 
    marginRight: '10px',
    color: '#F79009'
}

export const activateKeyStyle = { 
    textDecoration: 'none'
}

export const toolbarColor = {
     color: '#fff' 
}

export const currentPlanStyle = { 
    marginRight:'30px'
}

export const filePathStyle = { 
    fontSize:'small' 
}
