import React, { useState } from 'react';
import { useNavigate , useLocation } from 'react-router-dom';
import { Button, TextField, Grid, Paper, Typography, IconButton, Box } from '@mui/material';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import ErrorIcon from '@mui/icons-material/Error';
import { paperStyle , inputStyle , buttonStyle, boxStyle, gridStyle, text1Style, text2Style } from '../constants/ResetPasswordPageConst';
import AlertComponent from './subcomponents/AlertComponent';
import '../styles/ResetPassword.css';
import { confirmForgotPasswordPOST } from '../services/Endpoints';
import { EncodeData } from '../services/encodeData';

const ResetPassword: React.FC = () => {
  const [otp, setOtp] = useState('');
  const [userData, setUserData] = useState({ password: '', confirmPassword: '', showPassword: false });
  const [errorMessage, setErrorMessage] = useState('');
  const navigate = useNavigate();
  const location = useLocation();
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [keyMessage, setKeyMessage] = useState<string>('');
  const [errorType, setErrorType] = useState<string>('');

  const passwordCriteria = {
    isLengthValid: userData.password.length >= 12,
    hasUpperCase: /[A-Z]/.test(userData.password),
    hasLowerCase: /[a-z]/.test(userData.password),
    hasNumber: /[0-9]/.test(userData.password),
    hasSpecialChar: /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]+/.test(userData.password),
  };

  const isCombinationValid = passwordCriteria.hasUpperCase && passwordCriteria.hasLowerCase && passwordCriteria.hasNumber && passwordCriteria.hasSpecialChar;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, value } = e.target;
    setUserData(prevState => ({ ...prevState, [name]: value }));
    setErrorMessage('');
  };

  const handleConfirmPasswordChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUserData((prevState) => ({ ...prevState, confirmPassword: e.target.value }));
    setErrorMessage('');
  };
  const handleClickShowPassword = () => {
    setUserData({ ...userData, showPassword: !userData.showPassword });
  };

  const handleOtpChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setOtp(e.target.value);
  };

  const handleSubmit = async () => {
    if (!userData.password) {
      setErrorMessage("The Password field cannot be empty.");
      return;
    }

    if (!passwordCriteria.isLengthValid || !isCombinationValid) {
      setErrorMessage("Please make sure your password matches the above criteria.");
      return;
    }

    if (userData.password !== userData.confirmPassword) {
      setErrorMessage("Passwords do not match.");
      return;
    }
    if (!otp) {
      setErrorMessage("OTP field cannot be empty.");
      return;
    }
    const { email } = location?.state;
    if (!email) {
      setErrorMessage("Email not found.");
      return;
    }
    try {
      const payload = JSON.stringify({
        username : email,
        confirmationCode : otp,
        password : EncodeData(userData.password)
      });
      const response = await confirmForgotPasswordPOST(payload,'PUT')
      const data = await response.json();
      if(response.status === 200)
      {
        navigate('/login');
      }
      else
      {

      }
    } catch (error) {
      setOpenSnackbar(true);
    }
  };

  return (
    <Box sx={boxStyle}>
      <Grid container alignItems="center" justifyContent="center" sx={gridStyle}>
        <Paper elevation={10} sx={paperStyle}>
          <Typography variant="h4" align="center" gutterBottom sx={text1Style}>
            Reset Password
          </Typography>
          <TextField
            name="password"
            label="New Password"
            type={userData.showPassword ? 'text' : 'password'}
            variant="filled"
            fullWidth
            margin="normal"
            value={userData.password}
            onChange={handleChange}
            sx={inputStyle}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClickShowPassword}>
                  {userData.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            required
          />
          <div className='passwordValidationDiv'>
            <div className='validationDiv'>
              {passwordCriteria.isLengthValid ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error" />}
              <Typography variant="body1" style={text2Style}>Minimum 12 characters</Typography>
            </div>
            <div className='validationDiv'>
              {isCombinationValid ? <CheckCircleIcon color="success" /> : <ErrorIcon color="error" />}
              <Typography variant="body1" style={text2Style}>Combination of alphabets,numbers,and symbols</Typography>
            </div>
          </div>
          <TextField
            name="confirmPassword"
            label="Confirm New Password"
            type={userData.showPassword ? 'text' : 'password'}
            variant="filled"
            fullWidth
            margin="normal"
            value={userData.confirmPassword}
            onChange={handleConfirmPasswordChange}
            sx={inputStyle}
            InputProps={{
              endAdornment: (
                <IconButton onClick={handleClickShowPassword}>
                  {userData.showPassword ? <VisibilityOff /> : <Visibility />}
                </IconButton>
              ),
            }}
            required
          />
          {errorMessage && <Typography color="error">{errorMessage}</Typography>}
          <TextField
            label="Enter OTP"
            variant="filled"
            fullWidth
            margin="normal"
            value={otp}
            onChange={handleOtpChange}
            required
          />
          <Button fullWidth variant="contained" sx={buttonStyle} onClick={handleSubmit}>
            Confirm Submission
          </Button>
        </Paper>
      </Grid>
      <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
    </Box>
  );
};

export default ResetPassword;
