import { Player } from '@lottiefiles/react-lottie-player';
import '../../styles/errorPage.css'

export const ErrorPage = () => {
    return (
        <div className='noPageMainDiv' style={{}}>
            <Player
            autoplay
            loop
            src="https://lottie.host/7a415b6a-1822-45d5-ad2d-57ec1714640b/GMBbn2JY2j.json"
        >
        </Player>
        </div>
    )
}