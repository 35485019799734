export const containerStyle = { 
    py: 8,
    background: 'darkgrey',
    color: 'black',
    borderRadius: 1,
    marginBottom: '40px'
}

export const TextFieldStyle = { 
    my: 2,
    color: 'white'
}

export const buttonStyle = { 
    py: 1.5,
    mb: 4,
    backgroundColor: 'RGB(221, 128, 9)',
    color: 'black',
    '&:hover': { backgroundColor: 'gold' }
}