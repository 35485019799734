export const headerStyle = { 
    color:'#fff',
    fontWeight:'bold'
}

export const datasetCount = { 
    color:'#fff',
    marginLeft:'20px'
}

export const contentStyle = {
    color:'#fff'
}

export const subHeader = {
    color:'#f79009',
    fontWeight:'bold',
    // textAlign:'center'
}

export const premiumPlanStyle = { 
    color:'#f79009',
    textAlign:'end',
    cursor:'pointer',
    width:'max-content'
}