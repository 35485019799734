import { getFileFormat } from '../common/getFileFormat';
export const extractImageFilename = (url: string) => {
    const pattern = new RegExp(`([^/]+\\.${getFileFormat(url)})`);
    const match = url.match(pattern);

    if (match) {
      return match[1];
    } else {
      return null;
    }
};