import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Auth } from 'aws-amplify';
import VerifyAPIKey from './SetKey';

const OAuthCallback: React.FC = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const setupNewSession = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        if(user)
        {
          const key = await VerifyAPIKey();
          key ? navigate('/dashboard') : navigate('/login')
          window.location.reload();
        }
      } catch (error) {
        console.error('Error setting up new session:', error);
        navigate('/login'); 
      }
    };

    setupNewSession();
  }, [navigate]);

  return <div>Loading...</div>;
};

export default OAuthCallback;
