export const inputStyle = {
    marginBottom: '15px',
    backgroundColor: '#fff',
};

export const otpInputStyle = {
    ...inputStyle,
    input: {
        textAlign: 'center',
        fontSize: '1.5rem',
        letterSpacing: '0.3rem',
    },
    '& .MuiFilledInput-root': {
        after: {
            content: '""',
            display: 'block',
            margin: 'auto',
            width: '80%',
            '& .MuiInputBase-input': {
                fontSize: '1rem',

            },
            height: '2px',
            backgroundColor: 'transparent',
            transition: 'width 0.5s ease, backgroundColor 0.5s ease',
        },
        '&:after': {
            backgroundColor: '#ff8c00',
            width: '100%',
        },
        '&:hover:after': {
            backgroundColor: '#ffa500',
        },
    },
};

export const verifyButtonStyle = {
    padding: '10px 25px',
    fontSize: '1rem',
    fontWeight: 'bold',
    boxShadow: '0 5px 15px rgba(255, 165, 0, 0.4)',
    textTransform: 'none',
    background: 'linear-gradient(45deg, #fe6b8b 30%, #ff8e53 90%)',
    borderRadius: '30px',
    border: '0',
    color: 'white',
    height: '48px',
    '&:hover': {
        background: 'linear-gradient(45deg, #ff8e53 30%, #fe6b8b 90%)',
        boxShadow: '0 8px 25px rgba(255, 165, 0, 0.7)',
    },
};

export const paperStyle = {
    padding: '40px',
    height: 'auto',
    width: '400px',
    margin: '10% auto',
    backgroundColor: 'rgba(255, 165, 79, 0.7)',
    borderRadius: '15px',
    boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
    backdropFilter: 'blur(8.5px)',
    border: '1px solid rgba(255, 255, 255, 0.18)',
};

export const boxStyle = {
    backgroundColor: '#000',
    minHeight: '100vh',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    paddingTop: '64px',
    paddingBottom: '64px'
}

export const div1Style = {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

export const div2Style = {
    width: '100%',
    marginTop: '-110px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center'
}

export const text1Style = {
    color: '#fff',
    fontWeight: 'Light'
}

export const mobileStepperStyle = {
    backgroundColor: 'RGB(179, 116, 56)',
    marginBottom: '12px'
}

export const navigateButtonStyle = {
    color: 'black'
}

export const regenerateOTPBoxStyle = {
    width: '100%',
    display: 'flex',
    justifyContent: 'center',
    mt: 2
}

export const errorMessageTextStyle = {
    padding: '20px 20px 20px 20px'
}

export const homepageTextStyle = {
    mt: 4
}

export const routerStyle = {
    color: '#fff',
    textDecoration: 'none'
}

export const validationTextStyle = { 
    marginLeft: '20px',
    marginTop:'0px'
}