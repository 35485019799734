export const paperStyle = {
  p: '40px',
  maxWidth: '380px',
  height: 'auto',
  margin: '9% auto',
  background: 'rgba(255, 165, 79, 0.75)',
  borderRadius: '15px',
  boxShadow: '0 8px 32px 0 rgba(31, 38, 135, 0.37)',
  backdropFilter: 'blur(8.5px)',
  border: '1px solid rgba(255, 255, 255, 0.18)',
};

export const inputStyle = {
  backgroundColor: '#fff',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: '#eee'
  }
};

export const buttonStyle = {
  mt: '30px',
  backgroundColor: '#333',
  color: '#fff',
  transition: '0.3s',
  '&:hover': {
    backgroundColor: '#555'
  }
};

export const boxStyle = {
  height: '100vh',
  backgroundColor: '#000'
}

export const gridStyle = {
  height: '100%'
}

export const text1Style = {
  color: '#fff',
  fontWeight: 'bold'
}

export const text2Style = {
  marginLeft: '20px',
  marginTop: '0px'
}