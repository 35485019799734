    const URL = 'https://server.cortalinsight.com';

    const getEndpointHeaders: {} = {
        headers: {
            'x-api-key': localStorage.getItem('api-key')
        }   
    }

    const POSTEndpointHeaders : any = {
        headers: {
            'x-api-key': localStorage.getItem('api-key'),
            'Content-Type': 'application/json',
        },
        body : {},
        method : '' 
    }

    const deleteDatasetParams : any = {
        headers: {
            'x-api-key': localStorage.getItem('api-key')
        },
        method : "DELETE" 
    }

    const updatePOSTParams = (payload:{},method:string) => {
        POSTEndpointHeaders.body = payload;
        POSTEndpointHeaders.method = method;
    }

    export const getUserDetails = (params:{}) => {
        return fetch(`${URL}/user`,params)
    }

    export const userSignUpPOST = (payload: {},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/user/signup`, POSTEndpointHeaders)
    }

    export const confirmUserSignUpPOST = (payload: {},method:string) => {
        updatePOSTParams(payload,method)
        return fetch(`${URL}/user/confirmsignup`, POSTEndpointHeaders)
    };

    export const getresendCodeSignUp = (username: string) => {
        return fetch(`${URL}/user/resendCode/${username}`)
    }

    export const userSignInPOST = (params: {}) => {
        return fetch(`${URL}/user/signin`, params)
    }

    export const forgotPassword = (username: string) => {
        return fetch(`${URL}/user/forgotPassword/${username}`)
    }

    export const confirmForgotPasswordPOST = (payload: {},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/user/confirmForgot`, POSTEndpointHeaders)
    }

    export const resetPasswordPOST = (params: {}) => {
        return fetch(`${URL}/user/resetPassword`, params)
    }

    export const deleteUserPOST = (params: {}) => {
        return fetch(`${URL}/user/deleteUser`, params)
    }

    export const signOutUser = (token:string) => {
        return fetch(`${URL}/user/signOut`)
    }
    
    export const getMembershipKey = (params: {}) => {
        return fetch(`${URL}/organization/membership`, params)
    }

    export const updateOrganizationPOST = (params: {}) => {
        return fetch(`${URL}/user/orgUpdate`, params)
    }

    export const getDatasets = (paginationCount: number) => {
        return fetch(`${URL}/datasets/list/${paginationCount}`, getEndpointHeaders)
    }

    export const getDatasetInformation = (datasetId:string) => {
        return fetch(`${URL}/datasets/${datasetId}`,getEndpointHeaders)
    }

    export const clientBucketIntegratePOST = (payload: {},method:string) => {
        updatePOSTParams(payload,method)
        return fetch(`${URL}/files/transferData`, POSTEndpointHeaders)
    }

    export const startModelPOST = (payload: {},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/model/startModel`, POSTEndpointHeaders)
    }

    export const getImages = (params: {}) => {
        return fetch(`${URL}/files/get-images`, params)
    }

    export const setTagsPOST = (datasetId: string,payload:{},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/datasets/${datasetId}/tags`, POSTEndpointHeaders)
    }

    export const deleteImagesPOST = (datasetId:string,payload: {},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/datasets/${datasetId}/images`, POSTEndpointHeaders)
    }

    export const getEnlargedImage = (datasetId: string, fileName: string | null) => {
        return fetch(`${URL}/files/image/${datasetId}/${fileName}`, getEndpointHeaders)
    }

    export const getHealthMetrics = (datasetId: string) => {
        return fetch(`${URL}/datasets/${datasetId}/metadata`, getEndpointHeaders)
    }

    export const calculateHealthMetrics = (payload: {},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/metrics/healthMetrics`, POSTEndpointHeaders)
    }

    export const deleteAllImages = (datasetId: string,payload:{},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/datasets/${datasetId}/category`, POSTEndpointHeaders)
    }

    export const updateImageCategoryPOST = (datasetId:string,payload:{},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/datasets/${datasetId}/updateCategory`,POSTEndpointHeaders)
    }

    export const getDataAuditDetails = (datasetId: string) => {
        return fetch(`${URL}/datasets/${datasetId}/audit`, getEndpointHeaders)
    }

    export const getDuplicateCluster = (datasetId : string,page:number,pageSize:number,type:string) => {
        return fetch(`${URL}/datasets/${datasetId}/${page}/${pageSize}/${type}/cluster`,getEndpointHeaders)
    }

    export const updateDataAuditStatusPOST = (datasetId: string,payload:{},method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/datasets/${datasetId}/metadata/status`, POSTEndpointHeaders)
    }

    export const toggleMethodPOST = (datasetId: string, payload:{},method: string,value:string | null) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/datasets/${datasetId}/${value}`, POSTEndpointHeaders)
    }

    export const getSimilarImages = (datasetId: string, imageName: string | null,pageNumber:number , imageQty : number,thresholdValue:number) => {
        return fetch(`${URL}/vector/${datasetId}/${imageName}/${pageNumber}/${imageQty}/${thresholdValue}`, getEndpointHeaders)
    }

    export const calculateDataAudit = (payload:{} , method:string) => {
        updatePOSTParams(payload,method);
        return fetch(`${URL}/audit/calculate` , POSTEndpointHeaders)
    }

    export const organizationDetails = (payload:{},method:string) => {
        updatePOSTParams(payload,method)
        return fetch(`${URL}/datasets/results`,POSTEndpointHeaders)
    }

    export const getWorkflows = () => {
        return fetch(`${URL}/workflows`,getEndpointHeaders)
    }

    export const createWorkflowPOST = (payload:{},method:string) => {
        updatePOSTParams(payload ,method)
        return fetch(`${URL}/workflows`,POSTEndpointHeaders)
    }

    export const updateWorkflowPOST = (payload:{},method:string) => {
        updatePOSTParams(payload ,method)
        return fetch(`${URL}/workflows`,POSTEndpointHeaders)
    }

    export const deleteWorkflow = (payload:{},method:string) => {
        updatePOSTParams(payload ,method)
        return fetch(`${URL}/workflows`,POSTEndpointHeaders)
    }

    export const createDataset = (payload:{} , method :string) => {
        updatePOSTParams(payload , method)
        return fetch(`${URL}/datasets/create`,POSTEndpointHeaders)
    }

    export const uploadDatasetFiles = (payload:{} , method:string) => {
        updatePOSTParams(payload , method)
        return fetch(`${URL}/files/upload`,POSTEndpointHeaders)
    }

    export const uploadMultipartFiles = (payload:{} , method:string) => {
        updatePOSTParams(payload , method)
        return fetch(`${URL}/files/upload-part`,POSTEndpointHeaders)
    }

    export const completeMultipartUpload = (payload : {} , method : string) => {
        updatePOSTParams(payload, method)
        return fetch(`${URL}/files/complete-upload`,POSTEndpointHeaders)
    }

    export const assignTagsPOST = (payload : {} , method : string) => {
        updatePOSTParams(payload , method);
        return fetch(`${URL}/datasets/assignTags`,POSTEndpointHeaders)
    }

    export const deleteDataset = (datasetId : string) => {
        return fetch(`${URL}/datasets/${datasetId}`, deleteDatasetParams)
    }

    export const uploadPresignedURLFiles = (payload:any , URL: string) => {
        return fetch(URL, {
            body : payload,
            method : "PUT" ,
            headers : {
                "Content-Type": ""
            }
        })
    }
