import React, { useState } from 'react';
import Swal from 'sweetalert2';
import '../../styles/DatasetDetails.css'
import { calculateDataAudit } from '../../services/Endpoints';
import AlertComponent from './AlertComponent';
import { ThresholdProps } from '../../common/types';

export const GetThresholdDetails: React.FC<ThresholdProps> = ({datasetId,openLoader,closeLoader,closePopup}) => {
  
  const [keyMessage, setKeyMessage] = useState<string>('');
  const [errorType, setErrorType] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState(false);

    Swal.fire({
      title: "Threshold Details for Data Audit",
      background:'black',
      color:'white',
      html: `
        <div class="thresholdValueDiv">
          <label>Outliers threshold</label>  
          <input class="inputRange" type="range" id="outliersThresholdSlider" min="0" step="0.1" max="1.0" list="markers">
          <input type="number" class="inputText" id="outliersThresholdValue" placeholder="Outliers Threshold Value" value="0.5" />
          <label>Duplicate threshold</label>  
          <input class="inputRange" type="range" id="duplicateThresholdSlider" min="0" step="0.1" max="1.0" list="markers">
          <input type="number" class="inputText" id="duplicateThresholdValue" placeholder="Duplicate Threshold Value" value="0.5"/>
          <label>Near Duplicate threshold</label>  
          <input class="inputRange" type="range" id="nearDuplicateThresholdSlider" min="0" step="0.1" max="1.0" list="markers">
          <input type="number" class="inputText" id="nearDuplicateThresholdValue" placeholder="Near Duplicate Threshold Value" value="0"/>
          <datalist id="markers">
            <option value="0.1" label="0.1"></option>
            <option value="0.2" label="0.2"></option>
            <option value="0.3" label="0.3"></option>
            <option value="0.4" label="0.4"></option>
            <option value="0.5" label="0.5"></option>
            <option value="0.6" label="0.6"></option>
            <option value="0.7" label="0.7"></option>
            <option value="0.8" label="0.8"></option>
            <option value="0.9" label="0.9"></option>
            <option value="1.0" label="1.0"></option>
          </datalist>
        </div>
      `,
      showConfirmButton:true,
      confirmButtonText:'Get Similar Images',
      confirmButtonColor:'#f79009',
      showCancelButton: true,
      showCloseButton: true,
      didOpen: () => {
        const outliersInput:any = document.querySelector("#outliersThresholdSlider");
        const duplicateInput:any = document.querySelector("#duplicateThresholdSlider");
        const nearDuplicateInput:any = document.querySelector("#nearDuplicateThresholdSlider");
        const outliersValue: any = document.querySelector("#outliersThresholdValue");
        const duplicateValue: any = document.querySelector("#duplicateThresholdValue");
        const nearDuplicateValue: any = document.querySelector("#nearDuplicateThresholdValue");
        duplicateValue.value = duplicateInput.value;
        nearDuplicateValue.value = duplicateInput.value;
        outliersInput.addEventListener("input", (event:any) => {
          outliersValue.value = event.target.value;
        });
        duplicateInput.addEventListener("input", (event:any) => {
          duplicateValue.value = event.target.value;
        });
        nearDuplicateInput.addEventListener("input", (event:any) => {
          nearDuplicateValue.value = event.target.value;
        });
      },
      willClose: () => {
        closePopup()
      }
    })
    .then(async (result) => {
      if(result.isConfirmed)
      {
        openLoader();
        const outliersValue: any = document.querySelector("#outliersThresholdValue");
        const duplicateValue: any = document.querySelector("#duplicateThresholdValue");
        const nearDuplicateValue: any = document.querySelector("#nearDuplicateThresholdValue");
        const payload = JSON.stringify({
          "dataSetId": datasetId,
          "outlierThreshold": outliersValue.value,
          "duplicateThreshold": duplicateValue.value,
          "nearDuplicateThreshold": nearDuplicateValue.value
        })
        try{
          const response = await calculateDataAudit(payload , 'POST');
          const data = await response.json();
          setKeyMessage('Threshold updated successfully');
          setErrorType('success');
          setOpenSnackbar(true);
          closeLoader();
        }
        catch(err:any)
        {
          setKeyMessage(err.message);
          setErrorType('error');
          setOpenSnackbar(true);
          closeLoader();
        }
      }
    })
    return(
      <>
        <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
      </>
    )
  }