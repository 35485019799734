export const imageHighlightedStyle = {
  '.MuiPaper-root': {
    padding: '5px',
    background: '#f79009',
    borderRadius: '5px'
  }
}

export const paginationStyle = {
  '.MuiPaginationItem-root': {
    color: 'white'
  },
};

export const boxStyle = { 
  flex: '2',
  minWidth: '0'
}

export const selectedImageStyle = { 
  border: '#f79009 3px',
  borderStyle: 'solid'
}

export const buttonColor = {
  color: '#f79009',
  cursor: 'pointer'
}

export const imageText = {
  position: 'absolute',
  bottom: 0,
  width: '100%',
  textAlign: 'center',
  color: 'white',
  backgroundColor: 'rgba(0, 0, 0, 0.5)',
}

export const checkboxFilterStyle = {
  color: 'white'
}

export const noFiltersStyle = { 
  textAlign: 'center', 
  color: 'white', 
  fontSize: 'medium' 
} 

export const navButtonStyle = {
  width : 'max-content'
}