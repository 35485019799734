import styled from "styled-components";

export const AppStyles = styled.div.attrs({
    className: "app"
})``;

export const FooterStyles = styled.div.attrs({
    className: "footer w-screen lg:h-24 md:h-24 sm:h-12 xs:h-12 pt-5"
})`
    
`;

