import { createTheme } from '@mui/material/styles';

export const darkTheme = createTheme({
  palette: {
    mode: 'dark',
    background: {
      default: '#000',
      paper: 'rgb(0,0,0)',
    },
    primary: {
      main: '#f79009',
    },
    text: {
      primary: '#fff',
    },
  },
});

export const lightTheme = createTheme({
  palette: {
    mode: 'light',
    background: {
      default: '#fff',
      paper: '#fafafa',
    },
    primary: {
      main: '#f79009',
    },
    text: {
      primary: '#000',
    },
  },
});