import React, { useState, ChangeEvent } from 'react';
import {
  Button,
  TextField,
  Typography,
  Container,
} from '@mui/material';
import '../styles/ProfileSettings.css'
import { useNavigate } from 'react-router-dom';
import { updateOrganizationPOST } from '../services/Endpoints';
import AlertComponent from './subcomponents/AlertComponent';
import { TextFieldStyle, buttonStyle, containerStyle } from '../constants/ProfileSettingsConst';

const ProfileSettings: React.FC = () => {
  const [userDetails, setUserDetails] = useState({
    apiKey: ''
  });
  const [keyMessage , setKeyMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [errorType , setErrorType] = useState<string>("");
  const handleInputChange = (event: ChangeEvent<HTMLInputElement>) => {
    const { name, value } = event.target;
    setUserDetails({ ...userDetails, [name]: value });
  };

  const navigate = useNavigate();

  const accessToken = () => {
    let token = null;
    Object.keys(localStorage).forEach((key) => {
      if (key.includes('accessToken')) {
        token = localStorage.getItem(key);
      }
    });
    return token;
  };
  
  const handleSaveChanges = async () => {
    let token = accessToken();
    const config = {
      headers: {
        'authorization': `Bearer ${token}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        ...userDetails
      }),
      method: 'PUT',
    };
    try{
      const response = await updateOrganizationPOST(config);
      const data = await response.json();
      if (response.status === 200) {
        setKeyMessage('Key Activated Successfully');
        setErrorType('success');
        setOpenSnackbar(true);
        localStorage.getItem('show_demo') && localStorage.removeItem('show_demo')
        localStorage.setItem('api-key', userDetails.apiKey);
        navigate('/dashboard');
        window.location.reload();
      } 
    }
    catch(err:any)
    {
      console.error('Error:', err);
      setKeyMessage(`Error: ${err.errors[0].message}`);
      setErrorType('error');
      setOpenSnackbar(true);
    }
  };

  return (
    <div className='activateKeyDiv'>
      <Container maxWidth="sm" sx={containerStyle}>
      <Typography variant="h6" align="center" gutterBottom>
        Activate your key to access your organization's datasets
      </Typography>
      <TextField
        name="apiKey"
        label="Activation Key"
        type="text"
        fullWidth
        value={userDetails.apiKey} 
        onChange={handleInputChange}
        sx={TextFieldStyle}
      />
      <Button
        fullWidth
        variant="contained"
        sx={buttonStyle}
        onClick={handleSaveChanges}
      >
        Activate Key
      </Button>
      <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
    </Container>
    </div>
  );
};

export default ProfileSettings;
