import React, { useState, useEffect } from 'react';
import { Typography, Button, FormControlLabel, Checkbox, FormGroup, Autocomplete, TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme, lightTheme } from './subcomponents/themes';
import { Player } from '@lottiefiles/react-lottie-player';
import { fuzzySearch } from './subcomponents/fuzzySearch';
import { NoDataPage } from './subcomponents/NoData';
import { Image, Point } from "../common/types";
import debounce from "debounce";
import ms from 'ms';
import { useLocation, useNavigate} from 'react-router-dom';
import { FiltersData , SelectedFilters } from '../common/types';
import { Endpoints, backend } from "../services/requests";
import PointInfo from "./subcomponents/PointInfo";
import { PointInfoProps } from '../common/types';
import DatasetPlot from './subcomponents/DatasetPlot';
import { getImages, toggleMethodPOST } from '../services/Endpoints';
import { PreLoader } from './subcomponents/preloader';
import '../styles/exploreDatasets.css';
import AlertComponent from './subcomponents/AlertComponent';
import TutorialComponent from './subcomponents/DemoTour';
import { DemoDatasets } from '../constants/demoDatasets';
import { noPointInfoStyle } from '../styles/ExploreDatasetsConst';
import { autoCompleteStyle } from '../constants/AnalyticsPageConst';


const ExploreDataset: React.FC = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);
  const [categoryFilters, setCategoryFilters] = useState<FiltersData[]>([]);
  const [selectedFilters, setSelectedFilters] = useState<SelectedFilters>({});
  const [searchTerm, setSearchTerm] = useState<string>('');
  const [dataReady, setDataReady] = useState<boolean>(false);
  const [filteredCategoryFilters, setFilteredCategoryFilters] = useState<FiltersData[]>([]);
  const [allData, setAllData] = useState<Point[]>([]);
  const [keyMessage, setKeyMessage] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const location = useLocation();
  const [errorType, setErrorType] = useState<string>('');
  const [xPlotData, setXPlotData] = useState<number[]>([]);
  const [yPlotData, setYPlotData] = useState<number[]>([]);
  const [zPlotData, setZPlotData] = useState<number[]>([]);
  const [method, setMethod] = useState<string>('PCA');
  const [clickPoint, setClickPoint] = useState<PointInfoProps | null>(null);
  const [colors, setColors] = useState<string[]>([]);
  const [categoryColors, setCategoryColors] = useState<{ [category: string]: string }>({});
  const navigate = useNavigate();

  const onPointClick = async (point: Record<string, unknown>) => {
    const pointNumber = point.pointNumber as number;
    if (pointNumber !== undefined && allData.length > 0) {
      const point = allData[pointNumber] as Point;
      try {
        const { data: image } = await fetchImageDetails(point.image_name);
        setClickPoint({
          point,
          image,
        });
      } catch (error) {
        console.error("Failed to get image info", error);
      }
    }
  };

  const loadDataset = async (datasetId: string) => {
    const allX: number[] = [];
    const allY: number[] = [];
    const allZ: number[] = [];
    const color: string[] = [];
    try {
      const options = {
        "dataSetid": datasetId,
        ...selectedFilters
      }
      const { data: points } = await backend.post<Point[]>(
        `${Endpoints.Datasets}/points`, options
      );
      points.length > 0 ? setAllData(points) : setAllData([]);
      for (const { coordinates, category } of points) {
        const [x, y, z] = coordinates;
        allX.push(x);
        allY.push(y);
        allZ.push(z);
        const colorpick = categoryColors[category];
        color.push(colorpick);
      }
      setXPlotData(allX);
      setYPlotData(allY);
      setZPlotData(allZ);
      setColors(color);

    } catch (error) {
      setAllData([]);
      console.error("An error occurred in fetching", error);
      setKeyMessage(`An error occurred , ${error}`);
      setErrorType('error');
      setOpenSnackbar(true);
      // TODO handle errors
      // setError(....)
    } finally {
      setLoading(false);
    }
  };

  const fetchImageDetails = async (imageName: string) => {
    return await backend.get<Image>(
      `${Endpoints.Files}/image/${datasetId}/${imageName}`
    );
  };

  const changeMethod = (value:string | null) => {
    const payload = {};
    toggleMethodPOST(datasetId,payload,'POST',value)
    .then((response) => response.json())
    .then((data) => {
      setKeyMessage(data.message);
      setErrorType('success');
      setOpenSnackbar(true);
      setTimeout(() => {
        window.location.reload();
      },3000)
    })
    .catch((err) => {
      setKeyMessage(`An error occurred , ${err.message}`);
      setErrorType('error');
      setOpenSnackbar(true);
    })
  }

  useEffect(() => {
    fetchFilters();
  }, []);

  useEffect(() => {
    if (!location.state) {
      navigate('/dashboard');
    }
  },[location,navigate])

  useEffect(() => {
    assignCategoryColors(categoryFilters);
  }, [categoryFilters, selectedFilters]);

  useEffect(() => {
    if (categoryFilters.length > 0) {
      setDataReady(true);
    }
  }, [categoryFilters, categoryColors]);

  const datasetId = location.state?.datasetId;
  const datasetName = location.state?.datasetName;
  const graphMethod = location.state?.graphMethod;

  useEffect(() => {
    if (!datasetId || !dataReady) {
      return;
    }
    setLoading(true);
    loadDataset(datasetId);
  }, [datasetId, dataReady,selectedFilters]);

  const fetchFilters = () => {
    const config = {
      headers: {
        'x-api-key': `${localStorage.getItem('api-key')}`,
        'Content-Type': 'application/json'
      },
      body: JSON.stringify({
        "id": datasetId,
        "pageNum": 1,
        "imageQty": 1,
        "audit_issues" : null
      }),
      method: 'POST',
    };
    getImages(config)
      .then((response) => response.json())
      .then((data) => {
        const filtersData: FiltersData[] = [
          { uniqueTags: data.uniqueTags || [], uniqueCategory: [] , uniqueSubCategory:[] },
          { uniqueCategory: data.uniqueCategory || [], uniqueTags: [] , uniqueSubCategory:[] },
          { uniqueSubCategory: data.uniqueSubCategory , uniqueCategory: [] || [], uniqueTags: [] }
        ];
        setCategoryFilters(filtersData);
      })
      .catch((err) => {
      });
  };

  const handleCheckboxClick = (data: string, type: string) => {
    const filterArray: SelectedFilters = { ...selectedFilters };
    if (!filterArray.hasOwnProperty(type)) {
      filterArray[type] = [data];
    } else {
      const index = filterArray[type].indexOf(data);
      if (index === -1) {
        filterArray[type].push(data);
      } else {
        filterArray[type].splice(index, 1);
      }
    }
    setSelectedFilters({ ...filterArray });
  };

  const handleCheckboxToggle = (category: string, selectedFilters: SelectedFilters) => {
    for (const key in selectedFilters) {
      if (selectedFilters.hasOwnProperty(key)) {
        const filters = selectedFilters[key];
        if (Array.isArray(filters) && filters.includes(category)) {
          return true;
        }
      }
    }
    return false;
  };

  const assignCategoryColors = (category: FiltersData[]) => {
    const allCategories = category.flatMap(data => data.uniqueCategory);
    const colorMap: { [category: string]: string } = {};
    const predefinedColors = ['#FF5733', '#33FFB8', '#3370FF', '#FF33EC', '#FFC933', '#FF6A00', '#00FF99', '#0099FF', '#FF00E6', '#FFD933'];
    allCategories.forEach((category, index) => {
      colorMap[category] = predefinedColors[index % predefinedColors.length];
    });
    setCategoryColors(colorMap);
  };

  useEffect(() => {
    if (searchTerm) {
      let uniqueCategoryFilter: string[] = [];
      let uniqueSubCategoryFilter:string[] = [];
      let uniqueTagsFilter: string[] = [];

      let options = {
        threshold: 0.3
      };

      categoryFilters.forEach(({ uniqueCategory, uniqueTags , uniqueSubCategory }) => {
        if (uniqueCategory && uniqueCategory.length > 0) {
          uniqueCategoryFilter = fuzzySearch(uniqueCategory, options, searchTerm)
        } else if (uniqueTags && uniqueTags.length > 0) {
          uniqueTagsFilter = fuzzySearch(uniqueTags, options, searchTerm)
        }
        else if(uniqueSubCategory && uniqueSubCategory.length>0)
        {
          uniqueSubCategoryFilter = fuzzySearch(uniqueSubCategory , options , searchTerm)
        }
      });
      uniqueCategoryFilter.length === 0 && uniqueTagsFilter.length === 0 && uniqueSubCategoryFilter.length === 0 ? setFilteredCategoryFilters([]) : setFilteredCategoryFilters([{ uniqueCategory: uniqueCategoryFilter, uniqueTags: uniqueTagsFilter , uniqueSubCategory: uniqueSubCategoryFilter }]);
    } else {
      setFilteredCategoryFilters(categoryFilters);
    }
  }, [searchTerm, categoryFilters]);

  if (error) return <Typography color="error">{error}</Typography>;

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      {allData && allData.length > 0 && !DemoDatasets.includes(datasetId) && (
        <div className='toggleSelect'>
        <div className='calculatePoints'>
          <Autocomplete
            selectOnFocus
            id="select-on-focus"
            options={['PCA', 'TSNE', 'UMAP']}
            sx={autoCompleteStyle}
            onChange={(e, newValue) => { setMethod(String(newValue === null ? 'pca' : newValue)); changeMethod(newValue); }}
            renderInput={(params) => <TextField {...params} sx={{ color: 'white' }} label="Calculate Points" />}
          />
        </div>
      </div>
      )}
      <div className='mainDiv'>
        <div className='filtersSection'>
          <div className='filtersNav'>
            <input className='searchFilterInput' onChange={(e) => setSearchTerm(e.target.value)} placeholder='Search' />
            <Button sx={{ width: 'max-content' }} onClick={() => { setSelectedFilters({}); setSearchTerm(''); }}>Reset Filters</Button>
          </div>

          <div className='filtersDiv'>
            {filteredCategoryFilters.length === 0 ? (
              <div className='noResultDiv'>
                <Typography variant="caption" sx={{ textAlign: 'center', color: 'white', fontSize: 'medium' }}>
                  No Results Available
                </Typography>
              </div>
            ) : (filteredCategoryFilters.map((filter, key) => (
              <div className='listFilterDiv' key={key}>
                {Object.entries(filter).map(([categoryType, categories]) => (
                  categories.length !== 0 && (
                    <div key={categoryType}>
                      <h6 className='categoryTypeHeader'>{categoryType === 'uniqueTags' ? 'Quality Issues' : categoryType === 'uniqueCategory' ? 'Categories' : 'Sub Categories'}</h6>
                      <FormGroup>
                        {categories.map((category: string, index: number) => (
                          <FormControlLabel
                            key={index}
                            control={<Checkbox checked={handleCheckboxToggle(category, selectedFilters)} onClick={() => handleCheckboxClick(category, categoryType)} sx={{ color: 'white' }} />}
                            label={category !== null && category.split(" ").map((cate: string) => cate.slice(0, 1).toUpperCase() + cate.slice(1)).join("")}
                            sx={{ color: 'white' }} />
                        ))}
                      </FormGroup>
                    </div>
                  )
                ))}
              </div>
            )))}
          </div>
        </div>
        <>
          {loading ? (
            <div className='preloader'><PreLoader preloaderCheck={loading} /></div>
          ) : (
            <>
              {allData && allData.length > 0 ? (
                <>
                  <TutorialComponent type='exploreDataset' />
                  <div className='plotDiv'>
                    <DatasetPlot
                      name={graphMethod!== null ? `${datasetName!.toUpperCase()} using ${graphMethod.toUpperCase()}` : datasetName!.toUpperCase()}
                      x={xPlotData!}
                      y={yPlotData!}
                      z={zPlotData!}
                      color={colors!}
                      onPointClick={debounce(onPointClick, ms("1s"))}
                    />
                  </div>
                  <div className='pointInfoDiv'>
                    {clickPoint ? (
                    <PointInfo
                        point={clickPoint.point}
                        image={clickPoint.image}
                      />
                    ) : (
                      <div className='noPointInfoDiv'>
                        <Player
                          autoplay
                          loop
                          src="https://lottie.host/6d61bc6a-fb37-4e7a-9602-0f955ed01e28/ZKJ9ThKhO7.json"
                          style={noPointInfoStyle}
                        >
                        </Player>
                        <Typography variant='body1' sx={{ color: '#f79009', fontWeight: '700' }}>Click on the point to view image insights</Typography>
                      </div>
                    )}
                  </div>
                </>
              ) : (
                <div className='noDataPointsDiv'><NoDataPage displayText={'Please wait. Crunching the numbers'} /></div>
              )}
            </>
          )}
        </>
        <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
      </div>
    </ThemeProvider>
  );
};

export default ExploreDataset;
