import Shepherd from 'shepherd.js';
import {useEffect} from 'react';

const createTour = () => {
  const tour = new Shepherd.Tour({
    defaultStepOptions: {
      classes: 'shepherd-theme-arrows',
      scrollTo: true,
      cancelIcon: {
        enabled: true
      }
    },
    useModalOverlay: true
  });
  tour.steps = [];
  return tour;
};

export const DashboardTour = () => {
  const tour = createTour();
  useEffect(() => {
    tour.addStep({
      id: 'welcome',
      text: 'Welcome to the dashboard interactive demo.',
      attachTo: { element: '.welcome', on: 'bottom' },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'allDatasets',
      text: 'View all datasets created within your organization.',
      attachTo: { element: '.allDatasets', on: 'bottom' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });
  
    tour.addStep({
      id: 'createDataset',
      text: 'Create a new dataset via CLI tool or integrate with your storage by clicking this button.',
      attachTo: { element: '.addDataset', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });
    tour.start();
  }, []);
}

export const DatasetDetailsTour = () => {
  const tour = createTour();
  useEffect(() => {
    tour.addStep({
      id: 'snapshotDetails',
      text: 'Quickly view snapshot details under each dataset.',
      attachTo: { element: '.snapshotDetails', on: 'top' },
      buttons: [
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });
  
    tour.start();
  }, []);
}

export const ViewImagesTour = () => {
  const tour = createTour();
  useEffect(() => {
    tour.addStep({
      id: 'searchFilters',
      text: 'Search through datasets and inspect images seamlessly.',
      attachTo: { element: '.searchFilterDiv', on: 'right' },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });
  
    tour.addStep({
      id: 'pageNumberFilterInput',
      text: 'Click here to toggle between pages',
      attachTo: { element: '.pageNumberFilterInput', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'imageFilterNavbar',
      text: 'Click here to perform CRUD operations for images',
      attachTo: { element: '.imageFilterNavbar', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'listFilterDiv',
      text: 'Search and filter based on different metadata conditions',
      attachTo: { element: '.listFilterDiv', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'imagesList',
      text: 'Click on an image for detailed information.',
      attachTo: { element: '.imagesList', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });
    tour.start();
  }, []);
}

export const ExploreDatasetTour = () => {
  const tour = createTour();
  useEffect(() => {
    tour.addStep({
      id: 'toggleSelect',
      text: 'Toggle the modal to view underlying patterns in your datasets',
      attachTo: { element: '.toggleSelect', on: 'top' },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });
  
    tour.addStep({
      id: 'filtersNav',
      text: 'Filter points on the graph based on metadata conditions',
      attachTo: { element: '.filtersNav', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'plotDiv',
      text: 'Interact with the 3D scatterplot to analyze outliers, boundary samples, and class comparisons.',
      attachTo: { element: '.plotDiv', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });
    tour.start();
  }, []);
}

export const AnalyticsTour = () => {
  const tour = createTour();
  useEffect(() => {
    tour.addStep({
      id: 'analyticsSection',
      text: 'View important metrics calculated for your dataset.',
      attachTo: { element: '.analyticsSection', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });
  
    tour.addStep({
      id: 'graphToggleInput',
      text: 'Toggle between line and pie graph here',
      attachTo: { element: '.graphToggleInput', on: 'top' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });

    tour.start();
  }, []);
}

export const DataAuditTour = () => {
  const tour = createTour();
  useEffect(() => {
    tour.addStep({
      id: 'dataAuditHeader',
      text: 'Effortlessly search through pre-computed data audit sections to identify and isolate issues.',
      attachTo: { element: '.dataAuditHeader', on: 'top' },
      buttons: [
        {
          text: 'Next',
          action: tour.next
        }
      ]
    });

    tour.addStep({
      id: 'incidentTable',
      text: 'Gain insights into the severity and the number of impacted items.',
      attachTo: { element: '.tabulator', on: 'bottom' },
      buttons: [
        {
          text: 'Back',
          action: tour.back
        },
        {
          text: 'Finish',
          action: tour.complete
        }
      ]
    });

    // tour.addStep({
    //   id: 'ExploreDataIssues',
    //   text: 'Thoroughly investigate each issue and review incidents on the Explore Images page.',
    //   attachTo: { element: '.expandDiv', on: 'top' },
    //   buttons: [
    //     {
    //       text: 'Back',
    //       action: tour.back
    //     },
    //     {
    //       text: 'Next',
    //       action: function() {
    //         tour.next();
    //       } 
    //     }
    //   ]
    // });

    // tour.addStep({
    //   id: 'updateStatus',
    //   text: 'Update the status after taking the necessary actions.',
    //   attachTo: { element: '.updateStatus', on: 'top' },
    //   buttons: [
    //     {
    //       text: 'Back',
    //       action: tour.back
    //     },
    //     {
    //       text: 'Finish',
    //       action: tour.complete
    //     }
    //   ]
    // });

    tour.start();
  },[])
}