import React from 'react';
import '../../styles/TutorialComponent.css';
import { DashboardTour , DatasetDetailsTour , ViewImagesTour , ExploreDatasetTour, AnalyticsTour , DataAuditTour } from '../TourDetails';
import { DemoTourProps } from '../../common/types';

const TutorialComponent: React.FC<DemoTourProps> = ({ type }) => {
  const checkDemo = localStorage.getItem('show_demo');
    if (checkDemo === 'true') {
      switch(type) {
        case 'dashboard': DashboardTour(); break;
        case 'datasetDetails': DatasetDetailsTour(); break;
        case 'viewImages': ViewImagesTour(); break;
        case 'exploreDataset': ExploreDatasetTour(); break;
        case 'analytics': AnalyticsTour(); break;
        case 'dataaudit' : DataAuditTour(); break;
      }
    }

  return null;
};

export default TutorialComponent;
