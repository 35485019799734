import React, { useEffect, useState } from "react";
import Plot from "react-plotly.js";
import { DatasetPlotData } from "../../common/types";
import { Typography } from "@mui/material";
import '../../styles/DatasetPlot.css';

const DatasetPlot: React.FC<DatasetPlotData> = ({
    name,
    x,
    y,
    z,
    color,
    onPointClick,
}) => {
    const [data, setData] = useState<Array<Plotly.Data>>([]);
    const [currentBatch, setCurrentBatch] = useState(0);
    const [totalPoints,setTotalPoints] = useState<number>(100000);
    const batchSize = 1000;
    const interval = 100;

    useEffect(() => {
        const generateData = (batchNumber: number) => {
            const startIndex = batchNumber * batchSize;
            const endIndex = Math.min(startIndex + batchSize, totalPoints);

            const newX = x.slice(startIndex, endIndex);
            const newY = y.slice(startIndex, endIndex);
            const newZ = z.slice(startIndex, endIndex);
            const newColor = color.slice(startIndex, endIndex);

            const batchData: Plotly.Data = {
                x: newX,
                y: newY,
                z: newZ,
                mode: "markers",
                type: "scatter3d",
                marker: {
                    size: 5,
                    opacity: 0.3,
                    color: newColor,
                },
            };

            setData(prevData => [...prevData, batchData]);
        };

        const loadProgressiveData = () => {
            const intervalId = setInterval(() => {
                setCurrentBatch(prevBatch => {
                    if (prevBatch * batchSize >= totalPoints) {
                        clearInterval(intervalId);
                        return prevBatch;
                    }
                    generateData(prevBatch);
                    return prevBatch + 1;
                });
            }, interval);
        };

        loadProgressiveData();
    }, [x, y, z, color,totalPoints]);

    return (
       <>
        <Typography className='pointsDisplay'>Rendering {totalPoints > x.length ? x.length : totalPoints} points</Typography>
        {totalPoints < x.length && (<Typography className='pointsDisplay changePoints' onClick={() => setTotalPoints(x.length)}>Click here to view full points</Typography>)}
        <Plot
            data={data}
            layout={{
                title: name,
                hovermode: "closest",
                autosize: true,
                height: 800,
                uirevision: 1,
                dragmode: "orbit",
                paper_bgcolor: 'rgba(0,0,0,0)',
                plot_bgcolor: 'rgba(0,0,0,0)',
                // modebar: {
                //     orientation: 'h',
                //     buttons: [
                //         ['pan2d', 'zoomIn2d', 'zoomOut2d', 'autoScale2d'],
                //         ['select3d', 'lasso3d', 'zoom3d', 'resetCameraDefault3d']
                //     ]
                // } as any
            }}
            onClick={({ points }) => {
                // @ts-expect-error -- bad types
                onPointClick(points[0]);
            }}
            useResizeHandler={true}
            style={{ width: "100%", height: "100%" }}
        />
       </>
    );
};

export default DatasetPlot;