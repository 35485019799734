import React, { useState, useEffect } from 'react';
import { Box, Grid, Paper, Typography, Autocomplete, Button, CircularProgress , TextField } from '@mui/material';
import { ThemeProvider } from '@mui/material/styles';
import { darkTheme , lightTheme } from './subcomponents/themes';
import { useLocation, useNavigate } from 'react-router-dom';
import InfoIcon from '@mui/icons-material/Info';
import FilterAltIcon from '@mui/icons-material/FilterAlt';
import Highcharts from 'highcharts';
import HighchartsExporting from 'highcharts/modules/exporting';
import HighchartsFullScreen from 'highcharts/modules/full-screen';
import wordCloud from "highcharts/modules/wordcloud.js";
import HighchartsMore from 'highcharts/highcharts-more';
import HighchartsReact from 'highcharts-react-official';
import { NoDataPage } from './subcomponents/NoData';
import { PreLoader } from './subcomponents/preloader';
import { getHealthMetrics , calculateHealthMetrics } from '../services/Endpoints';
import '../styles/analytics.css'
import Swal from 'sweetalert2';
import AlertComponent from './subcomponents/AlertComponent';
import TutorialComponent from './subcomponents/DemoTour';
import { DemoDatasets } from '../constants/demoDatasets';
import { InsightType , ResolutionType } from '../common/types';
import { autoCompleteStyle, boxStyle, gridStyle, textStyle, iconStyle, preloaderStyle, filterIconStyle, graphPaperStyle } from '../constants/AnalyticsPageConst';

const Analytics: React.FC = () => {
  const [darkMode, setDarkMode] = useState(false);
  const [loading, setLoading] = useState(true);
  const [categoryLoader , setCategoryLoader] = useState<boolean>(false);
  const [openSnackbar, setOpenSnackbar] = useState(false);
  const [keyMessage, setKeyMessage] = useState<string>('');
  const [calculateButton, setCalculateButton] = useState<Boolean>();
  const [error, setError] = useState<string | null>(null);
  const [insights, setInsights] = useState<InsightType[]>([]);
  const [resolutionStatistics , setResolutionStatistics] = useState<ResolutionType[]>([]);
  const [imagesCount, setImagesCount] = useState<number | null>(null);
  const [annotationsCount, setAnnotationsCount] = useState<number | null>(null);
  const [errorType , setErrorType] = useState<string>("");
  const [completenessScore, setCompletenessScore] = useState<number | null>(null);
  const [dataHealth, setDataHealth] = useState<number | null>(null);
  const [consistencyStore, setConsistencyScore] = useState<number | null>(null);
  const [completenessScorePreloader, setCompletenessScorePreloader] = useState<boolean | null>(null);
  const [dataHealthPreloader, setDataHealthPreloader] = useState<boolean | null>(false);
  const [categoryType , setCategoryType] = useState<string>('category');
  const [consistencyStorePreloader, setConsistencyScorePreloader] = useState<boolean | null>(false);
  const [graphType, setGraphType] = useState<string>('pie');
  const location = useLocation();
  const navigate = useNavigate();

  const datasetId = location.state?.datasetId;
  useEffect(() => {
    if (!location.state) {
      navigate('/dashboard');
    }
    if (datasetId) {
      setCalculateButton(DemoDatasets.includes(datasetId));
      fetchData();
    }
  }, [categoryType]);

  if (!location.state) {
    return null;
  }

  HighchartsExporting(Highcharts);
  HighchartsFullScreen(Highcharts);
  HighchartsMore(Highcharts);
  wordCloud(Highcharts);

  const fetchData = async () => {
    try {
      imagesCount!==null ? setCategoryLoader(true): setLoading(true);
      setError(null);
      const response = await getHealthMetrics(datasetId)
      const data = await response.json();
      let dataCategory = '';
      switch(categoryType)
      {
        case 'category' : dataCategory = data.metadataAnalytics.category_analytics;break;
        case 'subcategory' : dataCategory = data.metadataAnalytics.subcategory_analytics;break;
        case 'supercategory' : dataCategory = data.metadataAnalytics.supercategory_analytics;break;
      }
      if(data?.resolution_stats!==null)
      {
        const resolutionData = data?.resolution_stats.map((data: any,key:number) => ({
          label: data.resolution,
          count: Number(data.count)
        }));
        setResolutionStatistics(resolutionData);
      }
      if(dataCategory)
      {
        const insightData = Object.entries(dataCategory).map(([category_name, categoryData]: [string, any]) => ({
          label: categoryData.category_name.split(" ").map((cate: string) => cate.slice(0, 1).toUpperCase() + cate.slice(1)).join(""),
          value: categoryData.percent * 100,
          count: categoryData.count
        }));
        setInsights(insightData);
      }
      setImagesCount(data.dataSet.num_of_images);
      setAnnotationsCount(data.dataSet.num_of_annotations);
      (data.dataSet.score_state === 'none' || data.dataSet.score_state === 'success') ? calculateScores(data.dataSet.data_quality_score,data.dataSet.completeness,data.dataSet.consistency) : handleCalculateScores()
    } catch (e) {
      setError('Failed to fetch data');
    } finally {
      imagesCount!==null ? setCategoryLoader(false): setLoading(false);
    }
  };

  const calculateMetrics = async (metricData:Object) => {
    const payload= JSON.stringify(metricData);
    await calculateHealthMetrics(payload,'POST')
      .then((response) => response.json())
      .then((data) => {
        setKeyMessage('Score calculated');
        setErrorType('success');
        setOpenSnackbar(true);
      })
      .catch((err) => {
        setKeyMessage(`Error , ${err}`);
        setErrorType('error');
        setOpenSnackbar(true);
      })
  }

  if (error) return (
    <>
      <NoDataPage displayText={'Lost in space, no analytics data available'}/>
    </>
  );

  function calculateScores(dataQuality:number,completeness:number,consistency:number) {
    setCompletenessScore(completeness);
    setConsistencyScore(consistency);
    setDataHealth(dataQuality);
  }

  const displayInfo = (title:string,description:string) => {
    Swal.fire({
      title: title,
      text: description,
      icon: "info",
      iconColor:'#f79009',
      background:'black',
      color:'white',
      showCancelButton: false,
      showCloseButton: true,
      showConfirmButton:false
    });
  }

  function handleScoreDetails(){
    !calculateButton && Swal.fire({
      title: "Metrics Calculator",
      background:'black',
      color:'white',
      html: `
        <div class="handleScoreDetailsDiv">
          <input class="handleScoreInput" type="number" id="width" placeholder="Enter width(px)" >
          <input class="handleScoreInput" type="number" id="height" placeholder="Enter height(px)">
          <input class="handleScoreInput" type="number" id="aspectRatio" placeholder="Enter Aspect Ratio">
          <input class="handleScoreInput" type="number" id="sensitivity" placeholder="Enter Sensitivity" min="0" max="100">
          <label>Blur threshold</label>  
          <input class="inputRange" type="range" id="blur_scaling_factor_slider" min="0" step="0.1" max="1.0" list="blur_scaling_factor_markers">
          <input type="number" class="inputText" id="blur_scaling_factor_value" placeholder="Blur Threshold Value" value="0.5" />
          <label>Noise threshold</label>  
          <input class="inputRange" type="range" id="noise_scaling_factor_slider" min="0" step="0.1" max="3.0" list="noise_scaling_factor_markers">
          <input type="number" class="inputText" id="noise_scaling_factor_value" placeholder="Noise Threshold Value" value="1.5" />
          <label>Light threshold</label>  
          <input class="inputRange" type="range" id="bright_scaling_factor_slider" min="0" step="0.1" max="1.0" list="blur_scaling_factor_markers">
          <input type="number" class="inputText" id="bright_scaling_factor_value" placeholder="Light Threshold Value" value="0.5" />
          <label>Dark threshold</label>  
          <input class="inputRange" type="range" id="dark_scaling_factor_slider" min="0" step="0.1" max="1.0" list="blur_scaling_factor_markers">
          <input type="number" class="inputText" id="dark_scaling_factor_value" placeholder="Dark Threshold Value" value="0.5" />
          
          <div class="imageTypeInput">
            <input type="checkbox" id="jpg" name="jpg" value="JPG">
            <label for="jpg">JPG</label><br>
            <input type="checkbox" id="jpeg" name="jpeg" value="JPEG">
            <label for="jpeg">JPEG</label><br>
            <input type="checkbox" id="png" name="png" value="PNG">
            <label for="png">PNG</label><br>
            <input type="checkbox" id="bmp" name="bmp" value="BMP">
            <label for="bmp">BMP</label><br>
          </div>
          <datalist id="blur_scaling_factor_markers">
            <option value="0.1" label="0.1"></option>
            <option value="0.2" label="0.2"></option>
            <option value="0.3" label="0.3"></option>
            <option value="0.4" label="0.4"></option>
            <option value="0.5" label="0.5"></option>
            <option value="0.6" label="0.6"></option>
            <option value="0.7" label="0.7"></option>
            <option value="0.8" label="0.8"></option>
            <option value="0.9" label="0.9"></option>
            <option value="1.0" label="1.0"></option>
          </datalist>
          <datalist id="noise_scaling_factor_markers">
            <option value="0.1" label="0.1"></option>
            <option value="0.2" label="0.2"></option>
            <option value="0.3" label="0.3"></option>
            <option value="0.4" label="0.4"></option>
            <option value="0.5" label="0.5"></option>
            <option value="0.6" label="0.6"></option>
            <option value="0.7" label="0.7"></option>
            <option value="0.8" label="0.8"></option>
            <option value="0.9" label="0.9"></option>
            <option value="1.0" label="1.0"></option>
            <option value="1.1" label="1.1"></option>
            <option value="1.2" label="1.2"></option>
            <option value="1.3" label="1.3"></option>
            <option value="1.4" label="1.4"></option>
            <option value="1.5" label="1.5"></option>
            <option value="1.6" label="1.6"></option>
            <option value="1.7" label="1.7"></option>
            <option value="1.8" label="1.8"></option>
            <option value="1.9" label="1.9"></option>
            <option value="2.0" label="2.0"></option>
            <option value="2.1" label="2.1"></option>
            <option value="2.2" label="2.2"></option>
            <option value="2.3" label="2.3"></option>
            <option value="2.4" label="2.4"></option>
            <option value="2.5" label="2.5"></option>
            <option value="2.6" label="2.6"></option>
            <option value="2.7" label="2.7"></option>
            <option value="2.8" label="2.8"></option>
            <option value="2.9" label="2.9"></option>
            <option value="3.0" label="3.0"></option>
          </datalist>
        </div>
      `,
      // showConfirmButton:true,
      confirmButtonText:'Calculate Metrics',
      confirmButtonColor:'#f79009',
      showCancelButton: true,
      showCloseButton: true,
      didOpen: () => {
        const blurScalingFactorInput:any = document.querySelector("#blur_scaling_factor_slider");
        const noiseScalingFactorInput:any = document.querySelector("#noise_scaling_factor_slider");
        const brightScalingFactorInput:any = document.querySelector("#bright_scaling_factor_slider");
        const darkScalingFactorInput:any = document.querySelector("#dark_scaling_factor_slider");
        const blueScalingFactorValue: any = document.querySelector("#blur_scaling_factor_value");
        const noiseScalingFactorValue: any = document.querySelector("#noise_scaling_factor_value");
        const brightScalingFactorValue: any = document.querySelector("#bright_scaling_factor_value");
        const darkScalingFactorValue: any = document.querySelector("#dark_scaling_factor_value");
        blueScalingFactorValue.value = blurScalingFactorInput.value;
        noiseScalingFactorValue.value = noiseScalingFactorInput.value;
        blurScalingFactorInput.addEventListener("input", (event:any) => {
          blueScalingFactorValue.value = event.target.value;
        });
        noiseScalingFactorInput.addEventListener("input", (event:any) => {
          noiseScalingFactorValue.value = event.target.value;
        });
        brightScalingFactorInput.addEventListener("input", (event:any) => {
          brightScalingFactorValue.value = event.target.value;
        });
        darkScalingFactorInput.addEventListener("input", (event:any) => {
          darkScalingFactorValue.value = event.target.value;
        });
        blueScalingFactorValue.addEventListener("input", (event:any) => {
          blurScalingFactorInput.value = event.target.value;
        });
        noiseScalingFactorValue.addEventListener("input", (event:any) => {
          noiseScalingFactorInput.value = event.target.value;
        });
        brightScalingFactorValue.addEventListener("input", (event:any) => {
          brightScalingFactorInput.value = event.target.value;
        });
        darkScalingFactorValue.addEventListener("input", (event:any) => {
          darkScalingFactorInput.value = event.target.value;
        });
      },
      willClose: () => {
      }
    }).then((result) => {
      if(result.isConfirmed)
      {
        const imageFormatData: string[] = [];
        const widthValue = (document.getElementById('width') as HTMLInputElement).value;
        const heightValue = (document.getElementById('height') as HTMLInputElement).value;
        const aspectRatioValue = (document.getElementById('aspectRatio') as HTMLInputElement).value;
        const sensitivityValue:number = parseFloat((document.getElementById('sensitivity') as HTMLInputElement).value);
        const blueScalingFactorValue:number = parseFloat((document.getElementById('blur_scaling_factor_value') as HTMLInputElement).value);
        const noiseScalingFactorValue:number = parseFloat((document.getElementById('noise_scaling_factor_value') as HTMLInputElement).value);
        const brightScalingFactorValue:number = parseFloat((document.getElementById('bright_scaling_factor_value') as HTMLInputElement).value);
        const darkScalingFactorValue:number = parseFloat((document.getElementById('dark_scaling_factor_value') as HTMLInputElement).value);
        const jpg = (document.getElementById("jpg") as HTMLInputElement)
        const jpeg = (document.getElementById("jpeg") as HTMLInputElement)
        const png = (document.getElementById("png") as HTMLInputElement)
        const bmp = (document.getElementById("bmp") as HTMLInputElement)
        jpg.checked && imageFormatData.push(jpg.value);
        jpeg.checked && imageFormatData.push(jpeg.value);
        png.checked && imageFormatData.push(png.value);
        bmp.checked && imageFormatData.push(bmp.value); 
        setKeyMessage('Calculating score , do not refresh the page');
        setErrorType('success');
        setOpenSnackbar(true);
        if(sensitivityValue <0 || sensitivityValue >100) {
          alert('Please enter values between 0 to 100')
          return;
        }
        else {
          calculateMetrics({
            "datasetId": datasetId,
            "width" : widthValue ? widthValue : null,
            "height" : heightValue ? heightValue : null,
            "aspect_ratio" : aspectRatioValue ? aspectRatioValue : null,
            "sensitivity" : sensitivityValue ? sensitivityValue : null, 
            "image_format": imageFormatData.length > 0 ? imageFormatData : null,
            "blur_scaling_factor" : blueScalingFactorValue,
            "noise_scaling_factor" : noiseScalingFactorValue,
            "bright_scaling_factor" : brightScalingFactorValue,
            "dark_scaling_factor" : darkScalingFactorValue
          });
          handleCalculateScores()
        }
      } 
    });
  }

  function handleCalculateScores() {
    setConsistencyScorePreloader(true);
    setCompletenessScorePreloader(true);
    setDataHealthPreloader(true);
    setCalculateButton(true);
  }

  function setChartOptions(chartName:string ,dataCategory : string,chartType: string, columnValue: string) {
    return (
      {
        chart: {
          type: chartType,
          backgroundColor: 'black',
        },
        title: {
          text: chartName,
          style: {
            color: 'white',
          },
        },
        xAxis: {
          categories: dataCategory === 'Insights' ? insights.map((data: any) => data.label) : resolutionStatistics.map((data: any) => data.resolution),
          title: {
            text:'',
            style: {
              color: 'white',
            },
          },
          labels: {
            style: {
              color: 'white',
            },
          },
          gridLineWidth : dataCategory === 'resolution' ? 1 : 0
        },
        yAxis: {
          title: {
            text:'',
            style: {
              color: 'white',
            },
          },
          labels: {
            style: {
              color: 'white',
            },
          },
          gridLineWidth : dataCategory === 'resolution' ? 1 : 0
        },
        series: [
          {
            colorByPoint: true,
            name: dataCategory === 'resolution' ? 'Resolution' : 'value',
            data:  dataCategory === 'Insights' ? insights.map((data: any) => ({ name: data.label , y: columnValue === 'Count' ? data.count : data.value })) : resolutionStatistics.map((data: any) => ({ name: data.label ,y: data.label[1], x: data.label[0], z:data.count})) ,
            color: '#F77F05'
          },
        ],
        exporting: {
          buttons: {
            contextButton: {
              menuItems: ['downloadPNG', 'downloadPDF', 'viewFullscreen']
            },
          },
        },
        credits: {
          enabled: false,
        },
        tooltip: dataCategory === 'resolution' ? {
          useHTML: true,
          shape:'callout',
          headerFormat: '<table>',
          pointFormat: dataCategory === 'resolution' ? '<tr><th colspan="2"><h3>{point.country}</h3></th></tr>' +
              '<tr><th>Dimensions:</th><td>{point.x}</td><td>X {point.y}</td></tr>' +
              '<tr><th>Image Count:</th><td>{point.z}</td></tr>' : `
              <tr><th>${dataCategory} ${columnValue}: </th><td>{point.x}</td> </tr>
              `,
          footerFormat: '</table>',
          followPointer: true
      } : {},
      }
    )
  }

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <div className='mainSection'>
        {loading ? (<><PreLoader preloaderCheck={loading}/></>) : (
          <Box sx={boxStyle}>
            <TutorialComponent type='analytics'/>
            <Typography variant="h4" sx={textStyle}>Analytics Dashboard</Typography>
            {/* <Switch checked={darkMode} onChange={() => {setDarkMode(!darkMode);fetchData();}} /> */}
            <div className='calculateScoresSection'>
              <Button className='primary' onClick={handleScoreDetails} disabled={calculateButton ? true : false}>Calculate Scores</Button>
            </div>
            <div className='analyticsSection'>
              <div className='analyticsCard'>
                <div className='analyticsInfoSection'>
                  <span className='analyticsInfoHeader'>Data Health</span>
                  {dataHealthPreloader ? (<div className='analyticsPreloader'><CircularProgress sx={preloaderStyle} /></div>) : (<h1 className='analyticsInfoValue'>{dataHealth ? dataHealth : '0'}%</h1>)}
                </div>
                {<div className='analyticsInfoImage'>
                  <img src="https://firebasestorage.googleapis.com/v0/b/cortalinsight.appspot.com/o/standard.png?alt=media&token=6c3a0446-1b9a-41c9-b0f4-3b057153392b" width="50px" height="50px" alt="Data Health " />
                </div>}
                <div className='analyticsInfoIcon analyticsIcon1'>
                  <InfoIcon sx={iconStyle} onClick={() => displayInfo('Data Health','Provides an overall assessment of data health, combining measures of consistency and completeness.')}/>
                </div>
              </div>
              <div className='analyticsCard'>
                <div className='analyticsInfoSection'>
                  <span className='analyticsInfoHeader'>Completeness Score</span>
                  {completenessScorePreloader ? (<div className='analyticsPreloader'><CircularProgress sx={preloaderStyle} /></div>) : (<h1 className='analyticsInfoValue'>{completenessScore ? completenessScore : '0'}%</h1>)}
                </div>
                {<div className='analyticsInfoImage'>
                  <img src="https://firebasestorage.googleapis.com/v0/b/cortalinsight.appspot.com/o/report.png?alt=media&token=2fac9f07-e53b-4ea9-863a-8311d07b5a23" width="50px" height="50px" alt="Completeness " />
                </div>}
                <div className='analyticsInfoIcon analyticsIcon1'>
                  <InfoIcon sx={iconStyle} onClick={() => displayInfo('Completeness Score','Ensure that images are accurately labeled and their metadata, is properly documented.')}/>
                </div>
              </div>
              <div className='analyticsCard'>
                <div className='analyticsInfoSection'>
                  <span className='analyticsInfoHeader'>Consistency Score</span>
                  {consistencyStorePreloader ? (<div className='analyticsPreloader'><CircularProgress sx={preloaderStyle} /></div>) : (<h1 className='analyticsInfoValue'>{consistencyStore ? consistencyStore : '0'}%</h1>)}
                </div>
                {<div className='analyticsInfoImage'>
                  <img src="https://firebasestorage.googleapis.com/v0/b/cortalinsight.appspot.com/o/cycle.png?alt=media&token=67c30d2a-27d8-4f92-8099-02b6f8f58f8a" width="50px" height="50px" alt="Consistency " />
                </div>}
                <div className='analyticsInfoIcon analyticsIcon2'>
                  <InfoIcon sx={iconStyle} onClick={() => displayInfo('Consistency Score','Maintain uniformity in formats, resolution, labeling  and aspect ratio across the dataset.')}/>
                  {!calculateButton && <FilterAltIcon sx={filterIconStyle} onClick={handleScoreDetails}/>}
                </div>
              </div>
              <div className='analyticsCard'>
                <div className='analyticsInfoSection'>
                  <span className='analyticsInfoHeader'>Number of Images</span>
                  {<h1 className='analyticsInfoValue'>{imagesCount ? imagesCount : '0'}</h1>}
                </div>
                <div className='analyticsInfoIcon analyticsIcon1'>
                  <InfoIcon sx={iconStyle} onClick={() => displayInfo('Number of Images', 'Total count of images in the dataset')} />
                </div>
              </div>
              <div className='analyticsCard'>
                <div className='analyticsInfoSection'>
                  <span className='analyticsInfoHeader'>Number of Annotations</span>
                  {<h1 className='analyticsInfoValue'>{annotationsCount ? annotationsCount : '0'}</h1>}
                </div>
                <div className='analyticsInfoIcon analyticsIcon1'>
                  <InfoIcon sx={iconStyle} onClick={() => displayInfo('Number of Annotations', '')} />
                </div>
              </div>
            </div>
            <div className='graphToggleInput'>
              <div>
                {insights.length > 0 && (
                  <div>
                    <Autocomplete
                      selectOnFocus
                      id="select-on-focus"
                      options={['Pie', 'Line']}
                      sx={autoCompleteStyle}
                      value={graphType}
                      onChange={(e, newValue) => { setGraphType(String(newValue === null ? 'pie' : newValue)); }}
                      renderInput={(params) => <TextField {...params} sx={textStyle} label="Graph" />}
                    />
                  </div>
                )}
              </div>
              <Autocomplete
                  selectOnFocus
                  id="select-on-focus"
                  options={['Category', 'Sub Category', 'Super Category']}
                  sx={autoCompleteStyle}
                  value={categoryType}
                  onChange={(e, newValue) => { setCategoryType(String(newValue === null ? 'category' : newValue.split(" ").join("").toLowerCase())); }}
                  renderInput={(params) => <TextField {...params} sx={textStyle} label="Category Type" />}
                />
            </div>
            <Grid container spacing={3} sx={gridStyle}>
              {categoryLoader ? (<><PreLoader preloaderCheck={loading} /></>) : (
                <>
                  {insights.length > 0 ? (<>
                    <Grid className='graph1' item xs={6} md={6}>
                      <Paper sx={graphPaperStyle}>
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={setChartOptions('Image Tagging Distribution','Insights', graphType.toLowerCase().split(" ").join(""), 'Value')}
                          />
                        </div>
                      </Paper>
                    </Grid>
                    <Grid className='graph2' item xs={6} md={6}>
                      <Paper sx={graphPaperStyle}>
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={setChartOptions('Image Composition','Insights', 'bar', 'Count')}
                          />
                        </div>
                      </Paper>
                    </Grid>
                    {resolutionStatistics.length > 0 && <Grid className='graph3' item xs={12} md={12}>
                      <Paper sx={graphPaperStyle}>
                        <div>
                          <HighchartsReact
                            highcharts={Highcharts}
                            options={setChartOptions('Image resolution statistics','resolution', 'bubble', 'Count')}
                          />
                        </div>
                      </Paper>
                    </Grid>}
                  </>) : (
                    <></>
                  )}
                </>
              )}
            </Grid>
            {insights.length ===0 && (<><NoDataPage displayText={'Lost in space, no analytics data available'} /></>)}
          </Box>
        )}
       <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
      </div>
    </ThemeProvider>
  );
};

export default Analytics;
