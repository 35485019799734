import React, { useEffect } from 'react';
import { AppBar, Typography, IconButton, Container, Grid, Paper, Box, Pagination } from '@mui/material';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { ThemeProvider } from '@mui/material/styles';
import { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { darkTheme , lightTheme } from './subcomponents/themes';
import { appBarStyle , containerStyle , addButtonStyle , paginationStyle , backgroundStyle , combinedPaperStyle, boxStyle, header1Style, header2Style, header3Style, datasetViewStyle, datasetTextStyle, paginationDivStyle } from '../constants/DashboardPageConst';
import { clientBucketIntegratePOST, createDataset, getDatasets } from '../services/Endpoints';
import '../styles/dashboard.css';
import { NoDataPage } from './subcomponents/NoData';
import { PreLoader } from './subcomponents/preloader';
import TutorialComponent from './subcomponents/DemoTour';
import AlertComponent from './subcomponents/AlertComponent';
import Swal from 'sweetalert2';
import { Auth } from 'aws-amplify';
import { DemoDatasets } from '../constants/demoDatasets';
import { DatasetTypes } from '../common/types';
import { getTimeDifference } from '../common/TimeDifference';
import { checkCookies, setCookies } from '../common/cookies';
import { ValidateUserPlan } from './subcomponents/ValidateUserDetails';


const Dashboard: React.FC = () => {
  const isActivated = useState(localStorage.getItem('api-key') !== undefined);
  const [dataset, setDataset] = useState<DatasetTypes[]>([]);
  const navigate = useNavigate();
  const [pageCount, setPageCount] = useState<number>();
  const [paginationCount, setPaginationCount] = useState<number>(1);
  const [preloader, setPreloader] = useState<boolean | null>(false);
  const [keyMessage, setKeyMessage] = useState<string>('');
  const [errorType, setErrorType] = useState<string>('');
  const [openSnackbar, setOpenSnackbar] = useState<boolean>(false);
  const [darkMode, setDarkMode] = useState<boolean>(false);

  useEffect(() => {
    const verifySession = async () => {
      try {
        const user = await Auth.currentAuthenticatedUser();
        setPreloader(true); 
        const data:any = await ValidateUserPlan();
        (getTimeDifference(data.created_at) > 30 && Number(localStorage.getItem('subscribeNotification')) < 3 && data.membership_type==='Free') && notifyTrailUsers()
        displayDatasets()
      } catch (error) {
        navigate('/login');
      }
    };
    verifySession();
  }, [navigate]);

  useEffect(() => {
    displayDatasets();
  }, [paginationCount]);

  const displayDatasets = async () => {
      getDatasets(paginationCount)
      .then((response) => response.json())
      .then((data) => {
        setDataset(data.dataSets);
        setPageCount(data.pages);
      })
      .catch((error) => {
        console.error('Error fetching dataset:', error);
        setDataset([]);
      })
      .finally(() => {
        setPreloader(false);
      });
  }

  const activateDataset = () => {
    Swal.fire({
      title: "Activate Datasets",
      background: 'black',
      color: 'white',
      text : `Activate your organization to create datasets`,
      showConfirmButton: true,
      confirmButtonText:'Activate your organization now',
      confirmButtonColor:'#f79009',
      showCancelButton: false,
      showCloseButton: true,
    })
    .then((result) => {
      if(result.isConfirmed)
      {
        navigate('/settings');
      }
    })
  }

  const createDatasetPopup = () => {
    Swal.fire({
      title: "Let's setup your dataset",
      background:'black',
      color:'white',
      html: `
        <div class="thresholdValueDiv">
          <label>Dataset Name</label>  
          <input class="inputText" id="datasetName" placeholder="Dataset Name" />
          <label>Task Type</label> 
          <select name="newCategory" id="taskType">
            <option value="classification">Classification</option>
            <option value="detection">Detection</option>
          </select> 
        </div>
      `,
      showConfirmButton:true,
      confirmButtonText:'Create Dataset',
      confirmButtonColor:'#f79009',
      showCancelButton: true,
      showCloseButton: true,
      didOpen: () => {
        document.getElementById("datasetName")?.addEventListener("input" , (e:any) => {
          e.target.value = e.target.value.replace(/ /g, '_');
        })
      },
    })
    .then(async (result) => {
      if(result.isConfirmed)
      {
        const datasetName = (document.getElementById("datasetName") as HTMLInputElement).value;
        const taskType = (document.getElementById("taskType") as HTMLInputElement).value;
        const payload = JSON.stringify({
          version : "1.0",
          dataSetName : datasetName,
          task : taskType
        });
        try
        {
          const response = await createDataset(payload , "POST");
          const data = await response.json();
          if(response.status === 201)
          {
            setKeyMessage(`New Dataset created, ${datasetName}`);
            setErrorType("success");
            setOpenSnackbar(true);
            setTimeout(() => {
              window.location.reload();
            }, 2000);
          }
          else
          {
            setKeyMessage(data.errors[0].message);
            setErrorType("error");
            setOpenSnackbar(true);
          }
        }
        catch (err: any) {
          setKeyMessage(err.message);
          setErrorType("error");
          setOpenSnackbar(true);
        }
      }
    })
  }

  const notifyTrailUsers = () => {
    Swal.fire({
      title: "Upgrade to Premium",
      background: 'black',
      color: 'white',
      text : 'Your trial access is almost over. Please subscribe now to enjoy premium features in the platform.',
      showConfirmButton: true,
      confirmButtonText:'Subscribe',
      confirmButtonColor:'#f79009',
      showCancelButton: true,
      cancelButtonText: 'Ignore this message',
      showCloseButton: true,
    })
    .then((result) => {
      if(result.isConfirmed)
      {
        
      }
      else if(result.isDismissed || result.isDenied)
      {
        let count = Number(localStorage.getItem('subscribeNotification')) +1;
        localStorage.getItem('subscribeNotification') ? localStorage.setItem('subscribeNotification', `${count}`) : localStorage.setItem('subscribeNotification', '1');
        // setCookies();
      }
    })
  }

  const handleCreateDataset = () => {
    checkDemoStatus() ? activateDataset() : createDatasetPopup()
    // Swal.fire({
    //   title: "Upload Datasets",
    //   background: 'black',
    //   color: 'white',
    //   html: `
    //     <div class="uploadDatasetsDiv">
    //       <div class="uploadTypeDiv uploadS3">
    //         <img class="uploadDatasetsImage" src="https://symbols.getvecta.com/stencil_24/8_s3-bucket-with-objects.8e17c9b9e5.svg">
    //         <h4 class="uploadTypeText">Upload via s3 bucket integration</h4>
    //       </div>
    //       <div class="uploadTypeDiv uploadCLI">
    //         <img class="uploadDatasetsImage" src="https://encrypted-tbn0.gstatic.com/images?q=tbn:ANd9GcRcIDO68jZxgenNfLpmmLBPLyqiEfE0cbscQw&s">
    //         <h4 class="uploadTypeText">Upload via CLI</h4>
    //       </div>
    //     </div>
    //   `,
    //   showConfirmButton: false,
    //   showCancelButton: false,
    //   showCloseButton: true,
    //   didOpen: () => {
    //     document.querySelector('.uploadS3')?.addEventListener('click', () => {
    //       handleS3BucketIntegration()
    //     });
    //     document.querySelector('.uploadCLI')?.addEventListener('click', () => {
    //       setKeyMessage('Please use the CLI tool to upload datasets');
    //       setErrorType('success');
    //       setOpenSnackbar(true);
    //     });
    //   },
    //   // willClose: () => {
    //   //   document.querySelector('.uploadS3')?.removeEventListener('click');
    //   //   document.querySelector('.uploadCLI')?.removeEventListener('click');
    //   // }
    // });
  };

  const checkDemoStatus = () => {
    let check = 0;
    dataset.map((data) => {
      DemoDatasets.includes(data.id) && check++
    })
    return check > 0 ? true : false
  }

  return (
    <ThemeProvider theme={darkMode ? darkTheme : lightTheme}>
      <Box sx={backgroundStyle}>
        <AppBar position="static" sx={appBarStyle}>
        </AppBar>
        <Container maxWidth="lg" sx={containerStyle}>
          {!isActivated &&
            (<Box
              sx={boxStyle}
            >
              <Typography variant="h4" component="div" gutterBottom sx={header1Style}>
                Focus more on innovation and less on data problems
              </Typography>
              <Typography variant="h6" component="div" gutterBottom sx={header2Style}>
                Begin with a Predefined Dataset
              </Typography>
            </Box>)}
          <Typography variant="h4" gutterBottom component="div" sx={header3Style}>
            Datasets
          </Typography>
          {preloader ? (<><PreLoader preloaderCheck={preloader}/></>) : (
            <>
              {dataset.length > 0 ? (
                <>
                <TutorialComponent type="dashboard"/>
                  <Grid container spacing={3} className='allDatasets'>
                    {dataset.map((data) => (
                      <Grid item xs={12} sm={6} lg={2} key={data.id}>
                        <Link
                          to="/datasetdetails"
                          state={{
                            datasetName: data.dataset_name,
                            datasetId: data.id,
                            createdDate: data.created_at,
                            createdBy: data.created_by,
                            updatedDate: data.updated_at,
                            imageUrl: data.image_url,
                            dataQualityScore: data.data_quality_score,
                            completenessScore: data.completeness,
                            consistencyScore: data.consistency,
                            scoreState: data.score_state,
                            modelState : data.model_state,
                            auditState : data.audit_state,
                            graphMethod : data.graph_method,
                            imagesCount : data.num_of_images,
                            task : data.task!==null && data.task 
                          }}
                          onClick={(e) => {
                            localStorage.setItem('organization_id', data.organization_id);
                          }}
                        >
                          <Paper
                            sx={datasetViewStyle}
                          >
                          </Paper>
                          <Typography variant="subtitle1" align="center" sx={datasetTextStyle}>
                            {data.dataset_name.toUpperCase()}
                          </Typography>
                        </Link>
                      </Grid>
                    ))}
                    <Grid item xs={12} sm={6} lg={2} className='addDataset'>
                      <Paper variant="outlined" sx={combinedPaperStyle} onClick={handleCreateDataset}>
                        <IconButton aria-label="add dataset">
                          <AddCircleOutlineIcon sx={addButtonStyle} />
                        </IconButton>
                      </Paper>
                      <Typography variant="subtitle1" align="center" sx={datasetTextStyle}>
                        CREATE
                      </Typography>
                    </Grid>
                  </Grid>
                  <Box sx={paginationDivStyle}>
                    <Pagination count={pageCount} shape="circular" onChange={(event, page) => { setPaginationCount(page); }} color="primary" sx={paginationStyle} />
                  </Box>
                </>
              ) : (
                <NoDataPage displayText={'Lost in space, no datasets available'} />
              )}
            </>
          )}
          <AlertComponent alertOpen={openSnackbar} message={keyMessage} type={errorType === 'error' ? 'error' : 'success'} closeAlert={() => setOpenSnackbar(false)}/>
        </Container>
      </Box>
    </ThemeProvider>
  );
};

export default Dashboard;