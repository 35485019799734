import React, { Key, useEffect, useState, useCallback } from 'react';
import { Backdrop, ToggleButton, ToggleButtonGroup, Typography } from "@mui/material"
import { LazyLoadImage } from "react-lazy-load-image-component"
import { backdropStyle, closeIconStyle, iconStyle, textStyle, toggleBorderColor, toggleColor } from "../../constants/EnlargedImageViewConst"
import LocalOfferIcon from '@mui/icons-material/LocalOffer';
import ArrowBackIcon from '@mui/icons-material/ArrowBack';
import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import CloseIcon from '@mui/icons-material/Close';
import { PreLoader } from './preloader';
import { enlargedImageViewProps } from '../../common/types';
import '../../styles/EnlargedImageView.css'
import '../../styles/Sidepane.css'

export const EnlargedViewComponent: React.FC<enlargedImageViewProps> = ({ openLightbox, enlargedImage, originalImage, imageDetails, previousImage, nextImage, onClose }) => {
  const [objectToggle, setObjectToggle] = useState<any>("object");

  useEffect(() => {
    if (openLightbox) {
      setObjectToggle("object");
    }
  }, [openLightbox])

  const handleKeyDown = useCallback((event) => {
    if (event.key === 'ArrowLeft') {
      previousImage();
    } else if (event.key === 'ArrowRight') {
      nextImage();
    } else if (event.key === 'Escape') {
      onClose();
    }

  }, [previousImage, nextImage]);

  useEffect(() => {
    window.addEventListener('keydown', handleKeyDown);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
    };
  }, [handleKeyDown]);

  return (
    <Backdrop
      open={openLightbox}
      style={backdropStyle}
    >
      {enlargedImage !== '' ? (<>
        <div className='enlargedViewDiv'>
          <div className='closeIconDiv'>
            <CloseIcon sx={closeIconStyle} onClick={onClose} />
          </div>
          <div className='imageDetailsDiv'>
            <LazyLoadImage
              alt={'enlarged image'}
              src={objectToggle === "full" ? enlargedImage : originalImage}
              effect='blur'
              width={'100%'}
              height={'80vh'}
            />
            <div className='detailsDiv'>
              {imageDetails && Object.keys(imageDetails).map((data, index) => (
                <div key={index}>
                  {Array.isArray(imageDetails[data]) && (
                    <div>
                      <Typography variant="subtitle1" gutterBottom sx={textStyle}>
                        {data !== null && data.split("_").join(" ").toUpperCase()}
                      </Typography>
                      {imageDetails[data].map((subData: string, subIndex: Key) => (
                        <div key={subIndex} className='categoryName'>
                          <LocalOfferIcon style={iconStyle} />
                          <Typography variant="body2" gutterBottom style={textStyle}>
                            {subData.split(" ").map((cate: string) => cate.slice(0, 1).toUpperCase() + cate.slice(1)).join("")}
                          </Typography>
                        </div>
                      ))}
                    </div>
                  )}
                </div>
              ))}
            </div>
            <div className='toggleButton'>
              <ToggleButtonGroup
                color="primary"
                value={objectToggle}
                exclusive
                onChange={(e, value) => {
                  console.log(e);
                  setObjectToggle(value)
                }}
                sx={toggleBorderColor}
                aria-label="Platform"
              >
                <ToggleButton
                  value="object"
                  sx={toggleColor}
                >
                  Object Image
                </ToggleButton>
                <ToggleButton
                  value="full"
                  sx={toggleColor}
                >
                  Full Size Image
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
          </div>
          <div className="toggleImageDiv">
            <ArrowBackIcon sx={closeIconStyle} onClick={previousImage} />
            <ArrowForwardIcon sx={closeIconStyle} onClick={nextImage} />
          </div>
        </div>
      </>) : (<PreLoader preloaderCheck={enlargedImage ? true : false} />)}
    </Backdrop>
  )
}